define("frontend/routes/portal/appointment-request/summary", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route"], function (_exports, _appointmentRequest, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      service = Ember.inject.service;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    company_id: computed.alias('appointmentRequestData.company_id'),
    flashMessage: service(),
    queryParams: {
      ids: {
        refreshModel: true,
        replace: true
      },
      requestId: {
        refreshModel: true,
        replace: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var ids = _ref.ids,
          requestId = _ref.requestId;
      var appointments = [];
      var appointmentRequest = null;

      if (ids) {
        var apptIds = ids.split(',');
        appointments = Ember.RSVP.Promise.all(apptIds.map(function (id) {
          return _this.store.findRecord('appointment', id);
        }));
      } else if (requestId) {
        appointmentRequest = this.store.findRecord('appointment-request', requestId, {
          reload: true
        });
      }

      this.set('ids', null);
      this.set('requestId', null);
      var company_id = this.get('company_id');
      var offeredServices = this.store.query('offered-service', {
        company_id: company_id
      }, {
        reload: true
      });
      return Ember.RSVP.hash({
        appointments: appointments,
        appointmentRequest: appointmentRequest,
        offeredServices: offeredServices
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.appointments.length > 0) {
        var firstAppointment = model.appointments.get('firstObject');
        var date = moment(firstAppointment.get('time')).tz(firstAppointment.get('company_time_zone')).format('YYYY-MM-DD');
        var time = moment(firstAppointment.get('time')).tz(firstAppointment.get('company_time_zone')).format('h:mmA');
        var timeFrameStart = firstAppointment.get('time_frame_start_time');
        var timeFrameEnd = firstAppointment.get('time_frame_end_time');
        this.setupForModifyingSummary(date, time, timeFrameStart, timeFrameEnd);
        this.set('modifyingAppointments', model.appointments);
        var pets = model.appointments.map(function (appt) {
          return appt.get('pet');
        });
        this.set('selectedPets', pets);
        this.set('company_id', firstAppointment.get('company_id'));
        this.set('household', firstAppointment.get('pet.household'));
        var offeredServiceId = firstAppointment.get('offered_service.id'); // eslint-disable-next-line no-unused-vars

        var _model$offeredService = model.offeredServices.filter(function (os) {
          return os.get('id') == offeredServiceId;
        }),
            _model$offeredService2 = _toArray(_model$offeredService),
            offeredService = _model$offeredService2[0],
            _rest = _model$offeredService2.slice(1);

        this.set('offeredService', offeredService);
        var addOnsWithPets = {};
        model.appointments.forEach(function (appt) {
          appt.get('appointment_add_on_services').forEach(function (aos) {
            if (!addOnsWithPets[aos.get('offered_service.id')]) {
              addOnsWithPets[aos.get('offered_service.id')] = Ember.Object.create({
                offered_service: aos.get('offered_service'),
                petIds: [],
                petNames: [],
                pets: []
              });
            }

            addOnsWithPets[aos.get('offered_service.id')].petIds.pushObject(appt.get('pet.id'));
            addOnsWithPets[aos.get('offered_service.id')].petNames.pushObject(appt.get('pet.name'));
            addOnsWithPets[aos.get('offered_service.id')].pets.pushObject({
              name: appt.get('pet.name')
            });
          });
        });
        this.set('appointmentRequestData.offeredServiceAddOns', Object.values(addOnsWithPets)); // WHAT SHOULD WE DO WITH SPECIAL INSTRUCTIONS?
      } else if (model.appointmentRequest) {
        if (model.appointmentRequest.get('approved_at') || model.appointmentRequest.get('declined_at')) {
          this.transitionTo('portal.messages');
          var answered = model.appointmentRequest.get('approved_at') ? 'approved' : 'declined';
          this.get('flashMessage').errorWithoutRefresh("Sorry, this appointment request has already been ".concat(answered, "!"));
          return;
        }

        var _date = model.appointmentRequest.get('startsAtMoment').format('YYYY-MM-DD');

        var _time = model.appointmentRequest.get('startsAtMoment').format('h:mmA');

        var _timeFrameStart = model.appointmentRequest.get('time_frame_start_time');

        var _timeFrameEnd = model.appointmentRequest.get('time_frame_end_time');

        this.setupForModifyingSummary(_date, _time, _timeFrameStart, _timeFrameEnd);
        this.set('modifyingAppointmentRequest', model.appointmentRequest);
        this.set('selectedPets', model.appointmentRequest.get('pets'));
        this.set('company_id', model.appointmentRequest.get('company_id'));
        this.set('household', model.appointmentRequest.get('household'));
        this.set('offeredService', model.appointmentRequest.get('offered_service.content'));
        this.set('specialInstructions', model.appointmentRequest.get('special_instructions'));
        var addOns = model.appointmentRequest.get('add_on_services').map(function (aos) {
          return Ember.Object.create({
            offered_service: model.offeredServices.find(function (os) {
              return os.get('id') == aos.offered_service.id;
            }),
            petIds: aos.pets.mapBy('id'),
            petNames: aos.pets.mapBy('name'),
            pets: aos.pets
          });
        });
        this.set('appointmentRequestData.offeredServiceAddOns', addOns);
      } else {
        if (this.get('selectedPets.length') === 0) {
          this.transitionTo('portal.appointment-request.pet-selection');
        }

        if (!this.get('offeredService')) {
          this.transitionTo('portal.appointment-request.service-selection');
        }

        if (this.get('appointments').length == 0) {
          this.transitionTo('portal.appointment-request.date-selection');
        }
      }

      this.set('appointmentRequestData.hasBeenOnSummary', true);
    },
    setupForModifyingSummary: function setupForModifyingSummary(date, time, timeFrameStart, timeFrameEnd) {
      this.get('appointmentRequestData').setup();
      this.set('appointmentRequestData.hasSelectedPets', true);
      this.set('appointmentRequestData.hasSelectedTimes', true);
      this.set('appointmentRequestData.hasSelectedDate', true);
      this.set('dates', [date]);
      this.set('visitsPerDay', 1);
      var appt = Ember.Object.create({
        id: 1,
        label: 'PREFERRED ARRIVAL WINDOW',
        collapsed: false
      });
      appt.set('timeFrame', Ember.Object.create({
        startTime: timeFrameStart,
        endTime: timeFrameEnd,
        time: time
      }));
      var dateTime = moment("".concat(date, " ").concat(time), 'YYYY-MM-DD h:mmA');

      if (dateTime.isBefore(moment("".concat(date, " 11:59AM"), 'YYYY-MM-DD h:mmA'))) {
        appt.set('timePeriodChoice', 'morning');
      } else if (dateTime.isBefore(moment("".concat(date, " 4:29PM"), 'YYYY-MM-DD h:mmA'))) {
        appt.set('timePeriodChoice', 'afternoon');
      } else {
        appt.set('timePeriodChoice', 'evening');
      }

      this.set('appointments', [appt]);
    }
  });

  _exports.default = _default;
});