define("frontend/controllers/invoices-summary", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    invoiceDetail: inject.controller('invoices-summary.invoice-detail'),
    paymentDetail: inject.controller('invoices-summary.payment-detail'),
    activeInvoiceId: computed.readOnly('invoiceDetail.model.id'),
    activePaymentId: computed.readOnly('paymentDetail.model.id'),
    showAddAdjustment: false,
    showAddPayment: false,
    deletingPayment: null,
    queryParams: ['showAddAdjustment', 'showAddPayment', 'backRoute', 'backHouseholdId', 'backId'],
    editing: computed.readOnly('invoiceDetail.editing'),
    media: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    onInvoiceDetail: computed.equal('currentRouteName', 'invoices-summary.invoice-detail'),
    onPaymentDetail: computed.equal('currentRouteName', 'invoices-summary.payment-detail'),
    detailsController: inject.controller('invoices-summary.invoice-detail'),
    editingInvoice: computed.readOnly('detailsController.editing'),
    hasClose: computed('showAddPayment', 'showAddAdjustment', 'onInvoiceDetail', 'editingInvoice', 'media.isMobile', 'saving', function () {
      if (this.get('showAddPayment') || this.get('showAddAdjustment')) {
        return false;
      }

      if (this.get('onInvoiceDetail')) {
        return !this.get('editingInvoice') && !this.get('saving') && !this.get('media.isMobile');
      } else {
        return !this.get('saving') && !this.get('media.isMobile');
      }
    }),
    sidePanelClassNames: computed('model.invoiceListItems.[]', function () {
      if (this.get('model.invoiceListItems.length') == 0) {
        return 'no-invoice-list-items';
      }
    }),
    invoices: computed('model.invoiceListItems.@each.invoice', function () {
      return this.get('model.invoiceListItems').filter(function (item) {
        return item.get('invoice') != null;
      }).map(function (listItem) {
        return listItem.get('invoice');
      });
    }),
    invoicePayments: computed('model.invoiceListItems.@each.invoice', function () {
      return this.get('model.invoiceListItems').filter(function (item) {
        return item.get('invoice_payment') != null;
      }).map(function (listItem) {
        return listItem.get('invoice_payment');
      });
    }),
    deletingPaymentInfoText: computed('deletingPayment', 'deletingPayment.payment_method', function () {
      return "... that you'd like to remove this $".concat(this.get('deletingPayment.amount'), " ").concat(this.get('deletingPayment.payment_method'), " payment?");
    }),
    actions: {
      saveAdjustment: function saveAdjustment(adjustment) {
        var _this = this;

        var firstDraftInvoice = this.get('invoices').filterBy('isDraft', true).get('firstObject.invoice_detail');
        var shouldReload = firstDraftInvoice == null;
        adjustment.set('invoice_detail', firstDraftInvoice);
        adjustment.set('updated_at', (0, _moment.default)());
        adjustment.set('household', this.get('model.household'));
        this.set('showAddAdjustment', false);
        adjustment.save().then(function () {
          if (shouldReload) {
            _this.send('refresh');
          } else {
            firstDraftInvoice.reload();
          }
        });
      },
      deletePayment: function deletePayment(payment) {
        this.set('deletingPayment', payment);
      },
      confirmDeletePayment: function confirmDeletePayment() {
        var _this2 = this;

        if (this.get('deleting')) {
          return;
        }

        this.set('deleting', true);
        var paymentId = this.get('deletingPayment.id');
        var lastItem = null;
        this.get('model.invoiceListItems').every(function (item) {
          if (item.get('invoice_payment.id') == paymentId) {
            return false;
          }

          lastItem = item;
          return true;
        });
        this.get('deletingPayment').destroyRecord().then(function () {
          if (lastItem === null) {
            _this2.transitionToRoute('invoices-summary', _this2.get('model.household.id'));
          } else if (lastItem.get('invoice')) {
            _this2.send('clickInvoice', lastItem.get('invoice'));
          } else if (lastItem.get('invoice_payment')) {
            _this2.send('clickPayment', lastItem.get('invoice_payment'));
          }

          _this2.send('refresh');
        }).finally(function () {
          _this2.set('deleting', false);
        });
      },
      savePayment: function savePayment(payment) {
        var _this3 = this;

        this.set('showAddPayment', false);

        if (this.get('savingPayment')) {
          return;
        }

        this.set('savingPayment', true);
        payment.set('household', this.get('model.household'));
        payment.save().then(function () {
          _this3.send('refresh');

          _this3.set('showAddPayment', false);
        }).catch(function () {
          var appController = Ember.getOwner(_this3).lookup('controller:application');
          appController.send('notify', {
            message: 'Oops! Something went wrong.',
            type: 'error'
          });
        }).finally(function () {
          return _this3.set('savingPayment', false);
        });
      }
    }
  });

  _exports.default = _default;
});