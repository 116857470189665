define("frontend/routes/edit-client/remove-household", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    actions: {
      remove: function remove() {
        var _this = this;

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        var household = this.get('controller.model');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.id, "/remove_company"),
          method: 'POST'
        }).always(function () {
          return _this.transitionTo('client-list').finally(function () {
            _this.set('saving', false);

            window.location.reload();
          });
        });
      },
      cancel: function cancel() {
        this.transitionTo('client-list.household.household-user', this.get('controller.model'), this.get('controller.model.owner'));
      }
    }
  });

  _exports.default = _default;
});