define("frontend/components/report-card-image-view", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['report-card-image-view'],
    media: inject.service(),
    coverPhoto: null,
    photos: [],
    selectedPhoto: null,
    isUploadingPhoto: false,
    isDeletingPhoto: false,
    isLoading: computed.or('isUploadingPhoto', 'isDeletingPhoto'),
    formattedDate: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).format('ddd, MMM D, YYYY');
    }),
    onAddAnotherSlide: computed.equal('selectedPhoto.id', undefined),
    isSelectedPhotoCoverPhoto: computed('coverPhoto.url', 'selectedPhoto.url', function () {
      return this.get('coverPhoto.url') === this.get('selectedPhoto.url');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setSelectedPhotoUrl();
      Ember.run.schedule('afterRender', function () {
        var selectedPhoto = _this.findPhotoInView();

        if (!selectedPhoto) {
          selectedPhoto = _this.get('coverPhoto');
        }

        _this.set('selectedPhoto', selectedPhoto);

        _this.setupMouseScroll();
      });
      (0, _jquery.default)('#report-card-carousel').on('scroll', function () {
        _this.set('selectedPhoto', _this.findPhotoInView());
      });
    },
    setupMouseScroll: function setupMouseScroll() {
      var slider = document.querySelector('#report-card-carousel');
      var isDown = false;
      var startX;
      var scrollLeft;
      slider.addEventListener('mousedown', function (e) {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', function () {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', function () {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', function (e) {
        if (!isDown) return;
        e.preventDefault();
        var x = e.pageX - slider.offsetLeft;
        var SCROLL_SPEED = 3;
        var walk = (x - startX) * SCROLL_SPEED;
        slider.scrollLeft = scrollLeft - walk;
      });
    },
    setSelectedPhotoUrl: function setSelectedPhotoUrl() {
      var image;

      if (this.get('coverPhoto')) {
        image = this.get('coverPhoto');
      } else {
        image = this.get('photos.firstObject');
      }

      this.set('selectedPhoto', image);
    },
    findPhotoInView: function findPhotoInView() {
      var visibleSlideId = null;
      var carousel = (0, _jquery.default)('#report-card-carousel');
      var carouselLeft = carousel.scrollLeft();
      var carouselRight = carouselLeft + carousel.outerWidth();
      (0, _jquery.default)('#report-card-carousel li').each(function (_, slide) {
        var slideLeft = slide.offsetLeft;
        var slideWidth = slide.offsetWidth;
        var slideRight = slideLeft + slideWidth;

        if (slideRight > carouselLeft && slideLeft < carouselRight) {
          visibleSlideId = slide.dataset.imageId;
        }
      });
      var selectedPhoto = null;

      if (visibleSlideId === this.get('coverPhoto.id')) {
        selectedPhoto = this.get('coverPhoto');
      } else {
        this.get('photos').forEach(function (photo) {
          if (visibleSlideId === photo.get('id')) {
            selectedPhoto = photo;
          }
        });
      }

      return selectedPhoto;
    },
    actions: {
      hidePhotos: function hidePhotos() {
        this.onClose();
      },
      changeCoverPhoto: function changeCoverPhoto() {
        var onCoverPhotoChanged = function onCoverPhotoChanged() {
          Ember.run.schedule('afterRender', function () {
            (0, _jquery.default)('#report-card-carousel').scrollLeft(0);
          });
        };

        this.onChangeCoverPhoto(this.get('selectedPhoto'), onCoverPhotoChanged);
      },
      removePhoto: function removePhoto() {
        var _this2 = this;

        var onPhotoRemovedCallback = function onPhotoRemovedCallback() {
          Ember.run.schedule('afterRender', function () {
            if (_this2.get('coverPhoto') === null && _this2.get('photos.length') === 0) {
              _this2.send('hidePhotos');

              return;
            }

            _this2.set('isDeletingPhoto', false);
          });
        };

        var selectedPhoto = this.findPhotoInView();
        this.set('isDeletingPhoto', true);
        this.onRemovePhoto(selectedPhoto, onPhotoRemovedCallback);
      },
      addedPhotoToReportCard: function addedPhotoToReportCard(file) {
        var _this3 = this;

        var onPhotoAdded = function onPhotoAdded() {
          _this3.set('isUploadingPhoto', false);

          Ember.run.schedule('afterRender', function () {
            (0, _jquery.default)('#report-card-carousel').scrollLeft(100000);
          });
        };

        this.set('isUploadingPhoto', true);
        this.onPhotoAddedToReportCard(file, onPhotoAdded);
      }
    }
  });

  _exports.default = _default;
});