define("frontend/routes/portal/appointment-request", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route"], function (_exports, _appointmentRequest, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    queryParams: {
      cid: {
        refreshModel: true,
        replace: true
      },
      redirectedFromMessage: {
        replace: true
      }
    },
    currentUser: inject.service(),
    model: function model(params) {
      var household = this.get('currentUser.user.household');
      this.get('appointmentRequestData').setup();
      this.set('household', household);
      var company_id = params.cid;
      this.set('company_id', company_id);
      this.set('redirectedFromMessage', params.redirectedFromMessage);
      return this.store.query('service-category', {
        company_id: company_id
      }, {
        reload: true
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.set('company_id', controller.get('cid'));
    },
    actions: {
      cancel: function cancel() {
        if (this.get('redirectedFromMessage')) {
          this.transitionTo('portal.messages.company', this.get('company_id'));
        } else {
          this.transitionTo('portal.appointments');
        }
      }
    }
  });

  _exports.default = _default;
});