define("frontend/components/invoice-receipt/adjustment-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['adjustment-list-item'],
    classNameBindings: ['editing', 'indentAdjustments', 'showEdit'],
    emptyDescription: computed.empty('adjustment.description')
  });

  _exports.default = _default;
});