define("frontend/controllers/portal/appointment-request/time-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    appointmentRequestData: inject.service(),
    companyId: computed.alias('appointmentRequestData.company_id'),
    visitsPerDay: computed.alias('appointmentRequestData.visitsPerDay'),
    showTabs: computed.lt('visitsPerDay', 4),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    dates: computed.alias('appointmentRequestData.dates'),
    appointments: computed.alias('appointmentRequestData.appointments'),
    existingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    hasExistingAppointments: computed.notEmpty('existingAppointments'),
    existingRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    hasExistingRequest: computed.notEmpty('existingRequest'),
    isModifying: computed.or('hasExistingRequest', 'hasExistingAppointments'),
    hasSelectedTimes: computed.alias('appointmentRequestData.hasSelectedTimes'),
    lateBookingSurchargeAppointments: computed.alias('appointmentRequestData.lateBookingSurchargeAppointments'),
    hasSeenLateFeeSurchargeModal: computed.alias('appointmentRequestData.hasSeenLateFeeSurchargeModal'),
    timeZone: computed.alias('offeredService.company_time_zone'),
    existingTimeFrame: computed('existingAppointments.[]', 'existingRequest', function () {
      var appts = this.get('existingAppointments');

      if (appts.get('length') > 0) {
        var appt = appts.get('firstObject');

        if (appt.get('time_frame_start_time')) {
          return {
            startTime: appt.get('time_frame_start_time'),
            endTime: appt.get('time_frame_end_time')
          };
        }
      }

      var existingRequest = this.get('existingRequest');

      if (existingRequest) {
        if (existingRequest.get('time_frame_start_time')) {
          return {
            startTime: existingRequest.get('time_frame_start_time'),
            endTime: existingRequest.get('time_frame_end_time')
          };
        }
      }
    }),
    existingTime: computed('existingAppointments.[]', 'existingRequest', function () {
      var appts = this.get('existingAppointments');

      if (appts.get('length') > 0) {
        var appt = appts.get('firstObject');
        return moment.parseZone(appt.get('time')).format('h:mmA');
      }

      var existingRequest = this.get('existingRequest');

      if (existingRequest) {
        return moment.parseZone(existingRequest.get('starts_at')).format('h:mmA');
      }
    }),
    showAddAppointmentButton: computed('appointments.[]', 'appointments.@each.{timeFrame,time}', function () {
      var apptsNotSelected = this.get('appointments').filter(function (appt) {
        return !appt.timeFrame && !appt.time;
      });

      if (apptsNotSelected.length == 0) {
        Ember.run.scheduleOnce('afterRender', function () {
          var ele = document.getElementById('add-appointment-button-row');

          if (ele) {
            ele.scrollIntoViewIfNeeded(true);
          }
        });
        return true;
      }

      return false;
    }),
    actions: {
      addNewAppointment: function addNewAppointment() {
        var appts = this.get('appointments');
        appts.forEach(function (appt) {
          appt.set('label', "".concat(moment.localeData().ordinal(appt.get('id')), " visit"));
          appt.set('collapsed', true);
        });
        var timePeriodChoice = 'morning';

        if (appts.get('lastObject.timePeriodChoice')) {
          var lastChoice = appts.get('lastObject.timePeriodChoice');

          if (lastChoice == 'morning') {
            timePeriodChoice = 'afternoon';
          } else {
            timePeriodChoice = 'evening';
          }
        }

        var id = appts.get('length') + 1;
        appts.pushObject(Ember.Object.create({
          id: id,
          label: "".concat(moment.localeData().ordinal(id), " visit"),
          timeFrame: null,
          collapsed: false,
          timePeriodChoice: timePeriodChoice
        }));
      },
      removeAppointment: function removeAppointment(appointment) {
        var appts = this.get('appointments');
        appts.removeObject(appointment);

        for (var i = 0; i < appts.get('length'); i++) {
          var appt = appts[i];
          appt.set('id', i + 1);
          appt.set('label', "".concat(moment.localeData().ordinal(appt.get('id')), " visit"));
        }

        if (appts.get('length') == 1) {
          appts.get('firstObject').set('label', 'PREFERRED ARRIVAL WINDOW');
        }

        appts.get('lastObject').set('collapsed', false);
      },
      backToDateSelection: function backToDateSelection() {
        this.transitionToRoute('portal.appointment-request.date-selection');
      },
      nextPage: function nextPage() {
        if (this.get('lateBookingSurchargeAppointments').length > 0 && !this.get('hasSeenLateFeeSurchargeModal')) {
          this.set('hasSeenLateFeeSurchargeModal', true);
          this.set('showLateFeeSurchargeModal', true);
        } else {
          this.set('hasSelectedTimes', true);
          this.transitionToRoute('portal.appointment-request.summary', {
            queryParams: {
              ids: null,
              requestId: null
            }
          });
        }
      },
      closeLateBookingPolicyModal: function closeLateBookingPolicyModal() {
        this.set('showLateFeeSurchargeModal', false);
        this.set('hasSelectedTimes', true);
        this.transitionToRoute('portal.appointment-request.summary', {
          queryParams: {
            ids: null,
            requestId: null
          }
        });
      },
      previousPage: function previousPage() {
        this.transitionToRoute('portal.appointment-request.service-selection');
      }
    }
  });

  _exports.default = _default;
});