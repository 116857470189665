define("frontend/components/portal-sidebar-nav", ["exports", "moment", "jquery", "frontend/config/environment"], function (_exports, _moment, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['sidebar-nav'],
    classNameBindings: ['collapsed'],
    tagName: 'nav',
    currentUser: Ember.inject.service(),
    beacon: Ember.inject.service(),
    collapsed: true,
    expanded: computed.not('collapsed'),
    timeZone: service(),
    session: service(),
    media: service(),
    accountSession: service(),
    inboxHasUnreadMessages: service(),
    hasUnreadMessages: computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    documentsLink: computed('currentUser.user.household.id', function () {
      var householdId = this.get('currentUser.user.household.id');
      var baseLink = _environment.default.environment == 'development' ? "http://localhost:3001" : "";
      return "".concat(baseLink, "/react/portal/").concat(householdId, "/documents");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('.ember-application').addClass('no-body-padding');
      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('.ember-application').removeClass('no-body-padding');
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    showSwitchAccount: computed('currentUser.user.staff_companies', function () {
      var staffCompanies = this.get('currentUser.user.staff_companies');

      if (staffCompanies && staffCompanies.length >= 1) {
        return true;
      }

      return false;
    }),
    handleMouseEnter: function handleMouseEnter() {
      if (this.get('media.isDesktop')) {
        this.set('collapsed', false);
      }
    },
    handleMouseLeave: function handleMouseLeave() {
      this.set('collapsed', true);
    },
    fullDate: computed('timeZone', function () {
      var tz = this.get('timeZone');
      return (0, _moment.default)().utcOffset(tz.get('offset')).format('dddd, MMMM D, YYYY');
    }),
    shortDate: computed('timeZone', function () {
      var tz = this.get('timeZone');
      return (0, _moment.default)().utcOffset(tz.get('offset')).format('MMM D');
    }),
    actions: {
      signOut: function signOut() {
        this.get('accountSession').signOut();
      },
      openSupport: function openSupport() {
        this.get('beacon').openSupportBeacon();
      }
    }
  });

  _exports.default = _default;
});