define("frontend/templates/components/weekend-surcharge-list-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0qoxG1Mq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"surcharge-list-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/appointment-requests/surcharge-icon.svg\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row policy-info\"],[8],[0,\"\\n      \"],[1,[24,[\"offeredService\",\"company_name\"]],false],[0,\" charges a \"],[1,[22,\"surchargeText\"],false],[0,\" surcharge on \"],[4,\"if\",[[28,\"gt\",[[24,[\"policy\",\"days_of_week\",\"length\"]],1],null]],null,{\"statements\":[[0,\"weekends (\"],[1,[24,[\"policy\",\"dayOfWeekLongDisplay\"]],false],[0,\")\"]],\"parameters\":[]},{\"statements\":[[1,[24,[\"policy\",\"dayOfWeekLongDisplay\"]],false],[0,\"s\"]],\"parameters\":[]}],[0,\". Just a heads up that one or more of your requested dates will be subject to this additional fee!\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"close\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/weekend-surcharge-list-modal.hbs"
    }
  });

  _exports.default = _default;
});