define("frontend/templates/components/holiday-surcharge-list-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PPnOK+vx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"surcharge-list-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/appointment-requests/holiday.svg\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row policy-info\"],[8],[0,\"\\n      You’re requesting an appointment on or around \"],[1,[22,\"holidayNames\"],false],[0,\".\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      Just a heads up, \"],[1,[24,[\"offeredService\",\"company_name\"]],false],[0,\" adds a \"],[7,\"span\",true],[10,\"class\",\"bold\"],[8],[1,[22,\"surchargeText\"],false],[0,\" holiday surcharge\"],[9],[0,\" for \"],[1,[24,[\"offeredService\",\"service_category\",\"shortened_name\"]],false],[0,\"s on \"],[1,[22,\"holidayRanges\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"close\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/holiday-surcharge-list-modal.hbs"
    }
  });

  _exports.default = _default;
});