define("frontend/routes/portal/appointment-request/service-selection", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route"], function (_exports, _appointmentRequest, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    saving: computed.alias('appointmentRequestData.saving'),
    company_id: computed.alias('appointmentRequestData.company_id'),
    errorHandler: inject.service(),
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('selectedPets.length') === 0) {
        this.transitionTo('portal.appointment-request.pet-selection');
      }
    },
    model: function model() {
      var company_id = this.get('company_id');
      var serviceCategories = this.store.query('service-category', {
        company_id: company_id
      }, {
        reload: true
      });
      var offeredServices = this.store.query('offered-service', {
        company_id: company_id
      }, {
        reload: true
      });
      return Ember.RSVP.hash({
        serviceCategories: serviceCategories,
        offeredServices: offeredServices
      });
    }
  });

  _exports.default = _default;
});