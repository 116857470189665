define("frontend/components/appointment-request-estimated-costs", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    classNames: ['appointment-request-estimated-costs'],
    appointmentRequestData: inject.service(),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    price: computed.alias('offeredService.latest_price'),
    incrementalPrice: computed.alias('offeredService.latest_incremental_price'),
    numTimes: computed.alias('appointmentRequestData.appointments.length'),
    numDates: computed.alias('appointmentRequestData.dates.length'),
    petsSelectedLength: computed.alias('appointmentRequestData.selectedPets.length'),
    salesTax: computed.alias('offeredService.company_sales_tax'),
    lateBookingSurchargeAppointments: computed.alias('appointmentRequestData.lateBookingSurchargeAppointments'),
    offHoursSurchargeAppointments: computed.alias('appointmentRequestData.offHoursSurchargeAppointments'),
    weekendSurchargeDates: computed.alias('appointmentRequestData.weekendSurchargeDates'),
    holidaySurchargeDates: computed.alias('appointmentRequestData.holidaySurchargeDates'),
    addOnServices: computed.alias('appointmentRequestData.offeredServiceAddOns'),
    holidayTooltipText: computed('offeredService.service_category.holiday_surcharge_policy', function () {
      var _this = this;

      var policy = this.get('offeredService.service_category.holiday_surcharge_policy');
      var amount;

      if (policy.get('amount_type') == 'fixed') {
        amount = "".concat(this.formatMoney(policy.get('amount')), "/").concat(policy.get('charge_per_day') ? 'day' : 'visit');
      } else {
        amount = "".concat(policy.get('amount'), "%");
      }

      var text = "".concat(amount, " holiday surcharge applied to:\n\n");
      this.get('holidaySurchargeDates').forEach(function (date) {
        text += "".concat(moment(date, 'YYYY-MM-DD').format('dddd, MMMM D'));

        if (_this.get('numTimes') > 1 && !policy.get('charge_per_day')) {
          text += " (x".concat(_this.get('numTimes'), ")");
        }

        text += '\n';
      });
      return text;
    }),
    weekendTooltipText: computed('offeredService.service_category.weekend_surcharge_policy', function () {
      var policy = this.get('offeredService.service_category.weekend_surcharge_policy');
      var amount;

      if (policy.get('amount_type') == 'fixed') {
        amount = "".concat(this.formatMoney(policy.get('amount')), " per ").concat(policy.get('charge_per_day') ? 'day' : 'visit');
      } else {
        amount = "".concat(policy.get('amount'), "%");
      }

      return "".concat(this.get('offeredService.company_name'), " charges a ").concat(amount, " surcharge on weekends (").concat(policy.get('dayOfWeekLongDisplay'), ")");
    }),
    lateBookingTooltipText: computed('offeredService.service_category.late_booking_surcharge_policy', function () {
      var policy = this.get('offeredService.service_category.late_booking_surcharge_policy');
      var amount;

      if (policy.get('amount_type') == 'fixed') {
        amount = "".concat(this.formatMoney(policy.get('amount')), " per visit");
      } else {
        amount = "".concat(policy.get('amount'), "%");
      }

      return "".concat(this.get('offeredService.company_name'), " charges a ").concat(amount, " late booking fee for requests made ").concat(policy.get('timingDescription'));
    }),
    offHoursTooltipText: computed('offeredService.service_category.off_hours_surcharge_policy', function () {
      var policy = this.get('offeredService.service_category.off_hours_surcharge_policy');
      var amount;

      if (policy.get('amount_type') == 'fixed') {
        amount = "".concat(this.formatMoney(policy.get('amount')), " per visit");
      } else {
        amount = "".concat(policy.get('amount'), "%");
      }

      var text = "".concat(amount, " off-hours surcharge applied to:\n\n");
      this.get('offHoursSurchargeAppointments').forEach(function (surchargeDate) {
        text += "".concat(moment(surchargeDate.date, 'YYYY-MM-DD').format('MMMM D'), " ").concat(surchargeDate.timeFrame.noSpacesStartTime, " - ").concat(surchargeDate.timeFrame.noSpacesEndTime);
        text += '\n';
      });
      return text;
    }),
    numAppts: computed('numTimes', 'numDates', function () {
      return this.get('numTimes') * this.get('numDates');
    }),
    addOnServiceCosts: computed('addOnServices.[]', 'numAppts', 'petsSelectedLength', function () {
      var _this2 = this;

      var addOnServiceCosts = [];
      this.get('addOnServices').forEach(function (addOnService) {
        var costPerAppt = addOnService.get('offered_service.latest_price') + (addOnService.get('petIds.length') - 1) * addOnService.get('offered_service.latest_incremental_price');

        var cost = costPerAppt * _this2.get('numAppts');

        addOnServiceCosts.pushObject({
          serviceName: addOnService.get('offered_service.nameWithDuration'),
          cost: cost,
          costPerAppt: costPerAppt,
          taxable: addOnService.get('offered_service.apply_sales_tax')
        });
      });
      return addOnServiceCosts;
    }),
    pricePerVisit: computed('petsSelectedLength', 'price', 'incrementalPrice', function () {
      return this.get('price') + (this.get('petsSelectedLength') - 1) * this.get('incrementalPrice');
    }),
    pricePerVisitWithAddOns: computed('pricePerVisit', 'addOnServiceCosts.@each.costPerAppt', function () {
      var pricePerVisit = this.get('pricePerVisit');
      this.get('addOnServiceCosts').forEach(function (addOnServiceCost) {
        pricePerVisit += addOnServiceCost.costPerAppt;
      });
      return pricePerVisit;
    }),
    taxablePricePerVisitWithAddOns: computed('pricePerVisit', 'offeredService', 'addOnServiceCosts.@each.{taxable,costPerAppt}', function () {
      var taxablePrice = 0;

      if (this.get('offeredService.apply_sales_tax')) {
        taxablePrice += this.get('pricePerVisit');
      }

      this.get('addOnServiceCosts').forEach(function (addOnServiceCost) {
        if (addOnServiceCost.taxable) {
          taxablePrice += addOnServiceCost.costPerAppt;
        }
      });
      return taxablePrice;
    }),
    offeredServiceCosts: computed('pricePerVisit', 'numAppts', function () {
      return this.get('pricePerVisit') * this.get('numAppts');
    }),
    appointmentsCost: computed('pricePerVisitWithAddOns', 'numAppts', function () {
      return this.get('pricePerVisitWithAddOns') * this.get('numAppts');
    }),
    holidayCosts: computed('holidaySurchargeDates.[]', 'pricePerVisitWithAddOns', function () {
      if (this.get('holidaySurchargeDates.length') == 0) {
        return 0;
      }

      var cost;
      var policy = this.get('offeredService.service_category.holiday_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        cost = policy.get('amount');
      } else {
        cost = this.get('pricePerVisitWithAddOns') * policy.get('amount') / 100.0;
      }

      var totalCost = cost * this.get('holidaySurchargeDates.length');

      if (policy.get('charge_per_day')) {
        return totalCost;
      } else {
        return totalCost * this.get('numTimes');
      }
    }),
    weekendCosts: computed('weekendSurchargeDates.[]', 'pricePerVisitWithAddOns', function () {
      if (this.get('weekendSurchargeDates.length') == 0) {
        return 0;
      }

      var cost;
      var policy = this.get('offeredService.service_category.weekend_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        cost = policy.get('amount');
      } else {
        cost = this.get('pricePerVisitWithAddOns') * policy.get('amount') / 100.0;
      }

      var totalCost = cost * this.get('weekendSurchargeDates.length');

      if (policy.get('charge_per_day')) {
        return totalCost;
      } else {
        return totalCost * this.get('numTimes');
      }
    }),
    lateBookingCost: computed('lateBookingSurchargeAppointments.[]', 'pricePerVisitWithAddOns', function () {
      if (this.get('lateBookingSurchargeAppointments.length') == 0) {
        return 0;
      }

      var cost;
      var policy = this.get('offeredService.service_category.late_booking_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        cost = policy.get('amount');
      } else {
        cost = this.get('pricePerVisitWithAddOns') * policy.get('amount') / 100.0;
      }

      return cost * this.get('lateBookingSurchargeAppointments.length');
    }),
    offHoursCost: computed('offHoursSurchargeAppointments.[]', 'pricePerVisitWithAddOns', function () {
      if (this.get('offHoursSurchargeAppointments.length') == 0) {
        return 0;
      }

      var cost;
      var policy = this.get('offeredService.service_category.off_hours_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        cost = policy.get('amount');
      } else {
        cost = this.get('pricePerVisitWithAddOns') * policy.get('amount') / 100.0;
      }

      return cost * this.get('offHoursSurchargeAppointments.length');
    }),
    taxableSubtotal: computed('taxablePricePerVisitWithAddOns', 'numAppts', 'lateBookingCost', 'weekendCosts', 'holidayCosts', 'offHoursCost', 'offeredService.apply_sales_tax', function () {
      var taxableAmount = this.get('taxablePricePerVisitWithAddOns') * this.get('numAppts');
      var offeredServiceApplySalesTax = this.get('offeredService.apply_sales_tax');

      if (offeredServiceApplySalesTax) {
        taxableAmount += this.get('lateBookingCost') + this.get('weekendCosts') + this.get('holidayCosts') + this.get('offHoursCost');
      }

      return taxableAmount;
    }),
    salesTaxCost: computed('taxablePricePerVisitWithAddOns', 'numAppts', 'lateBookingCost', 'weekendCosts', 'holidayCosts', 'offHoursCost', 'offeredService.apply_sales_tax', function () {
      if (!this.get('salesTax')) {
        return;
      }

      var tax = parseFloat(this.get('salesTax')) / 100.0;
      return this.get('taxableSubtotal') * tax;
    }),
    salesTaxTooltipText: computed('addOnServiceCosts.@each.{taxable,costPerAppt}', 'offeredService.apply_sales_tax', 'taxablePricePerVisitWithAddOns', 'numAppts', 'holidayCosts', 'weekendCosts', 'lateBookingCost', 'offHoursCost', function () {
      var allAreTaxable = [this.get('offeredService.apply_sales_tax')];
      this.get('addOnServiceCosts').forEach(function (addOnServiceCost) {
        allAreTaxable.pushObject(addOnServiceCost.taxable);
      });

      if (allAreTaxable.every(function (taxable) {
        return taxable;
      })) {
        return false;
      }

      var text = 'Taxable services:\n';

      if (this.get('offeredService.apply_sales_tax')) {
        text += "".concat(this.get('offeredService.nameWithDuration'), "\n");
      }

      this.get('addOnServiceCosts').forEach(function (addOnServiceCost) {
        if (addOnServiceCost.taxable) {
          text += "".concat(addOnServiceCost.serviceName, "\n");
        }
      });

      if (this.get('offeredService.apply_sales_tax')) {
        if (this.get('holidayCosts') > 0) {
          text += 'Holiday surcharge\n';
        }

        if (this.get('weekendCosts') > 0) {
          text += 'Weekend surcharge\n';
        }

        if (this.get('offHoursCost') > 0) {
          text += 'Off-hours surcharge\n';
        }

        if (this.get('lateBookingCost') > 0) {
          text += 'Late booking fee\n';
        }
      }

      text += "Taxable subtotal: ".concat(this.formatMoney(this.get('taxableSubtotal')));
      return text;
    }),
    totalCost: computed('appointmentsCost', 'holidayCosts', 'weekendCosts', 'lateBookingCost', 'offHoursCost', 'salesTaxCost', function () {
      return this.get('appointmentsCost') + this.get('holidayCosts') + this.get('weekendCosts') + this.get('lateBookingCost') + this.get('offHoursCost') + this.get('salesTaxCost');
    })
  });

  _exports.default = _default;
});