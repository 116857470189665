define("frontend/components/billing-inputs", ["exports", "frontend/utils/invoice-frequency-options", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _invoiceFrequencyOptions, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ["billing-inputs"],
    householdUser: null,
    household: computed.alias("householdUser.household"),
    noBillDeliveryRecipients: computed.readOnly("householdUser.household.noBillDeliveryRecipients"),
    saving: false,
    showCardPanel: false,
    currentUser: inject.service(),
    showingAutoPayModal: false,
    company: computed.readOnly("currentUser.user.company"),
    stripeInfo: inject.service(),
    flashMessage: inject.service(),
    plaidRedirect: false,
    hasPaymentMethod: computed.or("showSavedCard", "showSavedBankAccount"),
    disableBillingInfo: computed.and("household.isPendingStatus", "household.locked"),
    hasSentRequest: false,
    allInvoiceFrequencies: [],
    companyId: computed.readOnly("currentUser.user.company.id"),
    showConfirmationModalToTurnOffAutoBill: false,
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element.querySelectorAll("#re-send")).on("click", function () {
        if (!_this.get("hasSentRequest")) {
          var householdId = _this.get("household.id");

          var email = _this.get("household.request_sent_to");

          _this.set("hasSentRequest", true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/add_company"),
            method: "POST",
            data: {
              id: householdId,
              email: email
            }
          }).done(function () {
            _this.get("flashMessage").sendFlashMessage("Your request to connect was sent to ".concat(email), "Success");
          });
        }
      });
    },
    autoPayClarificationNote: computed("householdUser.household.isPendingStatus", "hasSentRequest", function () {
      var isPending = this.get("household.isPendingStatus");
      var isExistingUser = this.get("household.locked");
      var requiresAutoPay = this.get("company.auto_pay") == "required";
      var hasSentRequest = this.get("hasSentRequest");

      if (isPending && isExistingUser && requiresAutoPay) {
        return "<div>AutoPay will be enabled automatically when the ".concat(this.get("householdUser.household.owner.last_name"), " family confirms you as their care provider! ").concat(hasSentRequest ? "" : '<span class="re-send-request" id="re-send">re-send request</span>', "</div>");
      }

      if (isPending && isExistingUser && !requiresAutoPay) {
        return "<div>You won\u2019t be able to enable AutoPay until the ".concat(this.get("householdUser.household.owner.last_name"), " family confirms you as their care provider! ").concat(hasSentRequest ? "" : '<span class="re-send-request" id="re-send">re-send request</span>', "</div>");
      }
    }),
    fetchingCustomerInfo: computed.readOnly("stripeCustomerInfo.isPending"),
    stripeCustomerInfo: computed("householdUser.id", "currentUser.user.company.id", function () {
      var userId = this.get("householdUser.id");
      var companyId = this.get("currentUser.user.company.id");
      var promise = this.get("stripeInfo").fetchCustomerInfo(userId, companyId);
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    savedCardInfo: computed("stripeCustomerInfo.card", function () {
      var customer_info = this.get("stripeCustomerInfo");

      if (customer_info.get("card")) {
        return {
          brand: customer_info.get("card.brand"),
          last_four: customer_info.get("card.last_four")
        };
      } else {
        return null;
      }
    }),
    savedBankAccountInfo: computed("stripeCustomerInfo.bank_account", function () {
      var customer_info = this.get("stripeCustomerInfo");

      if (customer_info.get("bank_account")) {
        return {
          brand: customer_info.get("bank_account.bank_name"),
          last_four: customer_info.get("bank_account.last_four"),
          verified: customer_info.get("bank_account.verified")
        };
      } else {
        return null;
      }
    }),
    showSavedCard: computed("savedCardInfo", "stripeCustomerInfo.default", function () {
      return this.get("stripeCustomerInfo.default") === "card" && this.get("savedCardInfo");
    }),
    showSavedBankAccount: computed("savedBankAccountInfo", "stripeCustomerInfo.default", function () {
      return this.get("stripeCustomerInfo.default") === "bank_account" && this.get("savedBankAccountInfo");
    }),
    invoiceFrequencyOptions: computed("allInvoiceFrequencies.[]", "company.biweeklyStart", "company.biweeklyEnd", function () {
      var _this2 = this;

      if (!this.get("allInvoiceFrequencies")) {
        return [];
      }

      var invoiceFrequencies = this.get("allInvoiceFrequencies").map(function (invoiceFrequency) {
        var label = invoiceFrequency.get("displayName");
        var value = invoiceFrequency.get("name");

        var biweeklyStart = _this2.get("company.biweeklyStart");

        var biweeklyEnd = _this2.get("company.biweeklyEnd");

        var id = invoiceFrequency.get("id");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += " (".concat(biweeklyStart, " - ").concat(biweeklyEnd, ")");
        }

        return {
          value: value,
          label: label,
          id: id
        };
      }).sort(function (a, b) {
        return a.id - b.id;
      });
      return invoiceFrequencies;
    }),
    pricingOptions: [{
      value: false,
      label: "Standard"
    }, {
      value: true,
      label: "Custom"
    }],
    anySidePanelActive: false,
    autoPayClarify: computed("timePeriodClarify", function () {
      var timePeriodClarify = this.get("timePeriodClarify");
      return "We will automatically charge the payment method on file for the full outstanding balance ".concat(timePeriodClarify, " and send an itemized receipt instead of an invoice.");
    }),
    timePeriodClarify: computed("householdUser.invoice_frequency", function () {
      switch (this.get("householdUser.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    invoiceFrequencyClarify: computed("timePeriodClarify", function () {
      var timePeriodClarify = this.get("timePeriodClarify");

      if (timePeriodClarify) {
        return "Invoice emails will be sent ".concat(timePeriodClarify, " to:");
      } else {
        return "You have selected not to accrue how much this client owes you. No bills will be generated.";
      }
    }),
    updatePaymentMethod: function updatePaymentMethod(userId, companyId, token) {
      var _this3 = this;

      var paymentMethod = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "bank_account";
      this.set("updatingPaymentMethod", true);
      this.get("stripeInfo").createPaymentMethod(this.get("householdUser.user.id"), token, paymentMethod).then(function () {
        _this3.notifyPropertyChange("stripeCustomerInfo");

        _this3.set("updatingPaymentMethod", false);
      }).catch(function (result) {
        _this3.get("flashMessage").sendFlashMessage(result.payload.message, "user-error");

        _this3.set("showingAutoPayModal", true);

        _this3.set("updatingPaymentMethod", false);
      });
    },
    autoBillSwitch: computed("householdUser.invoice_frequency", function () {
      return this.get("householdUser.invoice_frequency") !== null;
    }),
    selectedInvoiceFrequency: computed("householdUser.invoice_frequency", "invoiceFrequencyOptions.[]", function () {
      return this.get("invoiceFrequencyOptions").filterBy("value", this.get("householdUser.invoice_frequency")).get("firstObject");
    }),
    actions: {
      setSaving: function setSaving() {
        if (this.setSaving) {
          this.setSaving();
        }
      },
      closePaymentMethodModal: function closePaymentMethodModal() {
        this.set("showingAutoPayModal", false);
        this.set("plaidRedirect", false);
      },
      savePaymentMethod: function savePaymentMethod(token, paymentMethod) {
        this.set("plaidRedirect", false);
        this.set("showingAutoPayModal", false);
        var userId = this.get("householdUser.user.id");
        var companyId = this.get("currentUser.user.company.id");
        this.updatePaymentMethod(userId, companyId, token, paymentMethod);
      },
      activateSidePanel: function activateSidePanel() {
        this.set("anySidePanelActive", true);
      },
      deactivateSidePanel: function deactivateSidePanel() {
        this.set("anySidePanelActive", false);

        if (this.reloadOwner) {
          this.reloadOwner();
        }
      },
      activateCustomPricesSidePanel: function activateCustomPricesSidePanel() {
        this.set("customPricesSidePanelActive", true);
        this.send("activateSidePanel");
      },
      deactivateCustomPricesSidePanel: function deactivateCustomPricesSidePanel() {
        this.set("customPricesSidePanelActive", false);
        this.send("deactivateSidePanel");
      },
      redirectToMicrodeposits: function redirectToMicrodeposits() {
        this.get("redirectToMicrodeposits")();
      },
      changeInvoiceFrequency: function changeInvoiceFrequency(invoiceFrequency) {
        var _this4 = this;

        this.set("previousInvoiceFrequency", this.get("householdUser.invoice_frequency"));
        this.set("householdUser.invoice_frequency", invoiceFrequency.value);
        var householdId = this.get("householdUser.household.id");
        var companyId = this.get("currentUser.user.company.id");
        this.set("loadingStartBillingDate", true);
        this.set("showInvoiceFrequencyEffectiveDate", true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/billing_start_date?company_id=").concat(companyId, "&invoice_frequency=").concat(invoiceFrequency.value),
          method: "GET"
        }).done(function (response) {
          _this4.set("newBillingPeriodStartDate", moment(response.start_date));

          _this4.set("loadingStartBillingDate", false);
        });
      },
      closeInvoiceFrequencyEffectiveDate: function closeInvoiceFrequencyEffectiveDate() {
        var successful = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (!successful) {
          this.set("householdUser.invoice_frequency", this.get("previousInvoiceFrequency"));
        }

        this.set("showInvoiceFrequencyEffectiveDate", false);
      },
      handleAutoBillSwitch: function handleAutoBillSwitch(autoBill) {
        if (autoBill) {
          this.send('changeAutoBillSwitch', autoBill);
        } else {
          this.set("showConfirmationModalToTurnOffAutoBill", true);
        }
      },
      turnOffAutoBillSwitch: function turnOffAutoBillSwitch() {
        this.send('changeAutoBillSwitch', false);
        this.send('hideConfirmationModalToTurnOffAutoBill');
      },
      hideConfirmationModalToTurnOffAutoBill: function hideConfirmationModalToTurnOffAutoBill() {
        this.set("showConfirmationModalToTurnOffAutoBill", false);
      },
      changeAutoBillSwitch: function changeAutoBillSwitch(autoBill) {
        var invoiceFrequencies = this.get("company.invoice_frequencies");

        if (!autoBill || invoiceFrequencies.get("length") === 0) {
          this.set("householdUser.invoice_frequency", null);
        } else {
          this.set("householdUser.invoice_frequency", this.get("company.default_invoice_frequency.name"));
        }

        if (this.get("saveHouseholdUser")) {
          this.get("saveHouseholdUser")(true);
        }
      },
      updateAutoPay: function updateAutoPay(autoPay) {
        if (this.get("company.auto_pay") == "required") {
          this.get("flashMessage").error("Sorry! ".concat(this.get("company.displayName"), " requires all clients to be on AutoPay!"));
          return;
        }

        this.set("householdUser.auto_pay", autoPay);

        if (this.get("saveHouseholdUser")) {
          this.get("saveHouseholdUser")();
        }
      },
      savedOfferedServicePrice: function savedOfferedServicePrice() {
        if (this.savedOfferedServicePrice) {
          this.savedOfferedServicePrice.apply(this, arguments);
        }
      },
      saveHouseholdUser: function saveHouseholdUser() {
        if (this.saveHouseholdUser) {
          this.saveHouseholdUser.apply(this, arguments);
        }
      },
      saveBillDeliveryOptions: function saveBillDeliveryOptions(household) {
        if (household.get("content.save")) {
          household = household.get("content");
        }

        household.save();
      },
      showRemovePaymentMethod: function showRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", true);
      },
      confirmRemovePaymentMethod: function confirmRemovePaymentMethod() {
        var _this5 = this;

        var householdId = this.get("household.id");
        var defaultMethod = this.get("stripeCustomerInfo.default");
        var payment_method_id = this.get("stripeCustomerInfo.".concat(defaultMethod, ".id"));
        var brand = this.get("stripeCustomerInfo.".concat(defaultMethod, ".brand"));
        var lastFour = this.get("stripeCustomerInfo.".concat(defaultMethod, ".last_four"));
        this.set("showRemovePaymentMethod", false);
        this.set("updatingPaymentMethod", true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/stripe_payment_method"),
          method: "DELETE",
          data: {
            payment_method_id: payment_method_id
          }
        }).then(function () {
          _this5.set("updatingPaymentMethod", false);

          _this5.notifyPropertyChange("stripeCustomerInfo");

          _this5.get("flashMessage").success("You've removed ".concat(brand, " ending ").concat(lastFour, " from the ").concat(_this5.get("householdUser.household.owner.last_name"), " family"));
        });
      },
      cancelRemovePaymentMethod: function cancelRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", false);
      }
    }
  });

  _exports.default = _default;
});