define("frontend/components/appointment-request-time-frame-tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    appointmentRequestData: inject.service(),
    currentUser: inject.service(),
    media: inject.service(),
    classNames: ['appointment-request-time-frame-tab-list'],
    appointments: [],
    timeWindowInMinutes: null,
    existingTimeFrame: null,
    existingTime: null,
    timeChoices: null,
    companyId: null,
    hasTimeFrames: null,
    timeFrames: computed('timeChoices.[]', function () {
      return this.get('timeChoices').map(function (choice) {
        return Ember.Object.create({
          startTime: choice.start_time,
          endTime: choice.end_time,
          time: choice.time,
          disabled: choice.disabled
        });
      });
    }),
    timeFramesWithExisting: computed('timeFrames.[]', 'existingTimeFrame', function () {
      var timeFrames = this.get('timeFrames');

      if (timeFrames.length > 0 && this.get('existingTimeFrame')) {
        var existing = Ember.Object.create(this.get('existingTimeFrame'));
        var foundWindow = timeFrames.filter(function (window) {
          return window.get('startTime') == existing.get('startTime') && window.get('endTime') == existing.get('endTime');
        });

        if (foundWindow.length == 0) {
          timeFrames.push(Ember.Object.create(this.get('existingTimeFrame')));
        }

        return this.sortTimeFrames(timeFrames);
      } else {
        return timeFrames;
      }
    }),
    morningWindows: computed('timeFrames.[]', 'existingTimeFrame', function () {
      return this.getWindows('', '11:59AM', 'before');
    }),
    afternoonWindows: computed('timeWindowInMinutes', 'existingTimeFrame', function () {
      return this.getWindows('12:00PM', '4:59PM', 'between');
    }),
    eveningWindows: computed('timeWindowInMinutes', 'existingTimeFrame', function () {
      return this.getWindows('5:00PM', '', 'after');
    }),
    getWindows: function getWindows(startTime, endTime, addExistingMethod) {
      var tz = this.get('offeredService.company_time_zone');
      var now = moment.tz(this.get('offeredService.company_time_zone'));
      startTime = moment.tz("".concat(now.format('YYYY-MM-DD'), " ").concat(startTime), 'YYYY-MM-DD h:mmA', tz);
      endTime = moment.tz("".concat(now.format('YYYY-MM-DD'), " ").concat(endTime), 'YYYY-MM-DD h:mmA', tz);
      var windows = [];
      this.get('timeFrames').filter(function (tf) {
        var tfEndTime = tf.get('endTime') || tf.get('time');
        tfEndTime = moment.tz("".concat(now.format('YYYY-MM-DD'), " ").concat(tfEndTime), 'YYYY-MM-DD h:mmA', tz);
        var tfStartTime = tf.get('startTime') || tf.get('time');
        tfStartTime = moment.tz("".concat(now.format('YYYY-MM-DD'), " ").concat(tfStartTime), 'YYYY-MM-DD h:mmA', tz);

        if (addExistingMethod == 'before' && tfStartTime.isSameOrBefore(endTime)) {
          windows.push(tf);
        }

        if (addExistingMethod == 'after' && tfEndTime.isSameOrAfter(startTime)) {
          windows.push(tf);
        }

        if (addExistingMethod == 'between' && tfEndTime.isSameOrBefore(endTime) && tfStartTime.isSameOrAfter(startTime)) {
          windows.push(tf);
        }
      });

      if (this.get('existingTimeFrame')) {
        var existing = Ember.Object.create(this.get('existingTimeFrame'));
        var foundWindow = windows.filter(function (window) {
          return window.get('startTime') == existing.get('startTime') && window.get('endTime') == existing.get('endTime');
        });

        if (foundWindow.length == 0) {
          var existingStart = moment(existing.get('startTime'), 'h:mmA');

          if (addExistingMethod === 'before') {
            if (existingStart.isSameOrBefore(endTime)) {
              windows.push(existing);
            }
          } else if (addExistingMethod === 'between') {
            if (existingStart.isSameOrAfter(startTime) && existingStart.isSameOrBefore(endTime)) {
              windows.push(existing);
            }
          } else {
            if (existingStart.isSameOrAfter(startTime)) {
              windows.push(existing);
            }
          }
        }
      } else if (this.get('existingTime')) {
        var _existing = Ember.Object.create({
          time: this.get('existingTime')
        });

        var foundTime = windows.filter(function (window) {
          return _existing.get('time') == window.get('time');
        });

        if (foundTime.length == 0) {
          var existingTime = moment(_existing.get('time'), 'h:mmA');

          if (addExistingMethod === 'before') {
            if (existingTime.isSameOrBefore(endTime)) {
              windows.push(_existing);
            }
          } else if (addExistingMethod === 'between') {
            if (existingTime.isSameOrAfter(startTime) && existingTime.isSameOrBefore(endTime)) {
              windows.push(_existing);
            }
          } else {
            if (existingTime.isSameOrAfter(startTime)) {
              windows.push(_existing);
            }
          }
        }
      }

      return this.sortTimeFrames(windows);
    },
    sortTimeFrames: function sortTimeFrames(timeFrames) {
      return timeFrames.sort(function (a, b) {
        if (a.get('startTime')) {
          var aStart = moment(a.get('startTime'), 'h:mmA');
          var bStart = moment(b.get('startTime'), 'h:mmA');

          if (aStart.isBefore(bStart)) {
            return -1;
          } else if (bStart.isBefore(aStart)) {
            return 1;
          } else {
            var aEnd = moment(a.get('endTime'), 'h:mmA');
            var bEnd = moment(b.get('endTime'), 'h:mmA');

            if (aEnd.isBefore(bEnd)) {
              return -1;
            } else {
              return 1;
            }
          }
        } else {
          var aTime = moment(a.get('time'), 'h:mmA');
          var bTime = moment(b.get('time'), 'h:mmA');

          if (aTime.isBefore(bTime)) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    },
    openNextTab: function openNextTab(appointment) {
      if (appointment == this.get('appointments.lastObject')) {
        return;
      } else {
        var changeNext = false;
        this.get('appointments').forEach(function (appt) {
          if (changeNext) {
            appt.set('collapsed', false);
            changeNext = false;
          }

          if (appt == appointment) {
            changeNext = true;
            appt.set('collapsed', true);
          }
        });
      }
    },
    actions: {
      openTab: function openTab(appointment) {
        this.get('appointments').forEach(function (appt) {
          if (appt == appointment) {
            appt.set('collapsed', false);
          } else {
            appt.set('collapsed', true);
          }
        });
      },
      clickTimeFrame: function clickTimeFrame(timeFrame, appointment) {
        appointment.set('timeFrame', timeFrame);
        this.openNextTab(appointment);
      },
      removeAppointment: function removeAppointment(appointment) {
        this.get('removeAppointment')(appointment);
      }
    }
  });

  _exports.default = _default;
});