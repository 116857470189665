define("frontend/controllers/portal/appointment-request/pet-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    appointmentRequestData: inject.service(),
    currentUser: inject.service(),
    selectedPets: computed.alias('appointmentRequestData.selectedPets'),
    selectedPetIds: computed.mapBy('selectedPets', 'id'),
    household: computed.alias('appointmentRequestData.household'),
    petsError: computed.equal('selectedPets.length', 0),
    hasSelectedPets: computed.alias('appointmentRequestData.hasSelectedPets'),
    existingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    hasExistingAppointments: computed.notEmpty('existingAppointments'),
    existingRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    hasExistingRequest: computed.notEmpty('existingRequest'),
    isModifying: computed.or('hasExistingRequest', 'hasExistingAppointments'),
    petOptions: computed('selectedPets.[]', 'household.pets.[]', function () {
      var selectedPetIds = this.get('selectedPetIds');
      var pets = this.get('household.pets');
      return pets.map(function (pet) {
        return {
          id: pet.get('id'),
          name: pet.get('name'),
          avatarOrPlaceholder: pet.get('avatarOrPlaceholder'),
          selected: selectedPetIds.includes(pet.get('id'))
        };
      });
    }),
    actions: {
      clickPetButton: function clickPetButton(petOption) {
        var selectedPets = this.get('selectedPets');
        var selectedPetIds = this.get('selectedPetIds');
        var pet = this.get('household.pets').findBy('id', petOption.id);

        if (selectedPetIds.includes(petOption.id)) {
          this.set('selectedPets', selectedPets.filter(function (selectedPet) {
            return selectedPet.get('id') != petOption.id;
          }));
        } else {
          selectedPets.pushObject(pet);
        }
      },
      nextPage: function nextPage() {
        if (this.get('petsError')) {
          return;
        }

        if (this.get('appointmentRequestData.hasBeenOnSummary')) {
          this.transitionToRoute('portal.appointment-request.summary', {
            queryParams: {
              ids: null,
              requestId: null
            }
          });
        } else {
          this.set('hasSelectedPets', true);
          this.transitionToRoute('portal.appointment-request.service-selection');
        }
      }
    }
  });

  _exports.default = _default;
});