define("frontend/components/holiday-surcharge-list-modal", ["exports", "frontend/mixins/money-formater", "frontend/mixins/dates-to-ranges", "frontend/mixins/holiday-names-from-dates", "frontend/utils/string-util"], function (_exports, _moneyFormater, _datesToRanges, _holidayNamesFromDates, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend(_holidayNamesFromDates.default, _datesToRanges.default, _moneyFormater.default, {
    offeredService: null,
    surchargeText: computed('offeredService.service_category.holiday_surcharge_policy', function () {
      var policy = this.get('offeredService.service_category.holiday_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        return this.formatMoney(policy.get('amount'));
      } else {
        return "".concat(policy.get('amount'), "%");
      }
    }),
    holidayNames: computed('holidayDates.[]', 'offeredService.company_country', function () {
      return _stringUtil.default.joinWithAnd(this.holidayNamesFromDates(this.get('holidayDates'), this.get('offeredService.company_country'), this.get('offeredService.company_state')));
    }),
    holidayRanges: computed('holidayDates.[]', 'offeredService.company_country', function () {
      return _stringUtil.default.joinWithAnd(this.holidayDatesFromSelectedDates(this.get('holidayDates'), this.get('offeredService.service_category.holiday_surcharge_policy'), this.get('offeredService.company_country'), this.get('offeredService.company_state')));
    }),
    actions: {
      close: function close() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});