define("frontend/routes/portal/appointment-request/pet-selection", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route"], function (_exports, _appointmentRequest, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    model: function model() {
      var household = this.get('household');
      return Ember.RSVP.hash({
        pets: household.get('pets'),
        household: household
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (this.get('selectedPets.length') == 0) {
        this.set('selectedPets', model.pets.sortBy('id'));
      }

      if (model.pets.length === 1) {
        this.set('appointmentRequestData.hasSelectedPets', true);
        this.transitionTo('portal.appointment-request.service-selection');
      }
    }
  });

  _exports.default = _default;
});