define("frontend/controllers/portal/notifications", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    // queryParams: ['company_id'],
    currentUser: inject.service(),
    flashMessage: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    isLoading: true,
    companyUserProfiles: null,
    companyName: null,
    ownerUserId: computed('household', 'household.owner', function () {
      if (this.get('household.owner')) {
        return this.get('household.owner').get('id');
      }

      return null;
    }),
    clientCompanies: computed('ownerUserId', function () {
      var _this = this;

      var userId = this.get('ownerUserId');

      if (userId) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/users/get_client_companies"),
          method: 'GET'
        }).then(function (response) {
          _this.set('clientCompanies', response);
        });
      }

      return null;
    }),
    companiesWithSettings: computed('activeCompanies.[]', function () {
      var _this2 = this;

      var activeCompanies = this.get('activeCompanies');

      if (activeCompanies) {
        var _activeCompanies$;

        var apiPromises = [];
        activeCompanies.forEach(function (_ref) {
          var company_id = _ref.company_id;
          apiPromises.push(_jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/notifications"),
            method: 'GET',
            data: {
              user_id: _this2.get('ownerUserId'),
              company_id: company_id
            }
          }));
        });
        Ember.RSVP.Promise.all(apiPromises).then(function (response) {
          _this2.set('companyUserProfiles', response);
        });
        if (activeCompanies.length === 1) this.set('companyName', (_activeCompanies$ = activeCompanies[0]) === null || _activeCompanies$ === void 0 ? void 0 : _activeCompanies$.company_name);
        if (activeCompanies.length >= 1) this.set('isLoading', false);
        return $.map(activeCompanies, function (companySettings) {
          return companySettings.company_name;
        });
      }

      return [];
    }),
    selectedCompanySettings: computed('activeCompanies.[]', function () {
      var companySettings = this.get('activeCompanies');

      if (companySettings && companySettings.length > 0) {
        var settings = companySettings;
        var firstSettings = settings.get('firstObject');
        this.set('companyName', firstSettings.company_name);
        return firstSettings;
      }

      return null;
    }),
    activeCompanies: computed('clientCompanies.[]', function () {
      var companySettings = this.get('clientCompanies.settings');

      if (companySettings) {
        return companySettings.filter(function (settings) {
          return !settings.company_deactivated && !settings.denied_request && settings.pet_parents_activated || settings.staff_status == 'Owner';
        });
      }

      return [];
    }),
    selectedCompanyUserProfile: computed('companyUserProfiles.[]', function () {
      var companyUserProfiles = this.get('companyUserProfiles');

      if (companyUserProfiles && companyUserProfiles.length > 0) {
        var settings = companyUserProfiles;
        return settings.get('firstObject');
      }

      return null;
    }),
    actions: {
      changeCompany: function changeCompany(companyName) {
        var currentUser = this.get('currentUser'); // Set selected company

        var companySettings = this.get('clientCompanies');
        var settings = companySettings.settings.find(function (s) {
          return s.company_name == companyName;
        });
        this.set('selectedCompanySettings', settings); // Set selected company user profile

        var companyId = settings.company_id;
        var selectedCompanyUserProfile = this.get('companyUserProfiles').find(function (cup) {
          return cup.company_id == companyId;
        });
        this.set('selectedCompanyUserProfile', selectedCompanyUserProfile);
        this.set('companyName', companyName);
      }
    }
  });

  _exports.default = _default;
});