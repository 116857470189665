define("frontend/services/appointment-request-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setup();
    },
    resetAppointments: function resetAppointments() {
      var firstAppointment = Ember.Object.create({
        id: 1,
        label: 'PREFERRED ARRIVAL WINDOW',
        timeFrame: null,
        collapsed: false,
        timePeriodChoice: 'morning'
      });
      this.set('appointments', [firstAppointment]);
    },
    setup: function setup() {
      this.set('hasSelectedPets', false);
      this.set('hasSelectedDate', false);
      this.set('hasSelectedTimes', false);
      this.set('hasBeenOnSummary', false);
      this.set('household', null);
      this.set('company_id', null);
      this.set('selectedPets', []);
      this.set('offeredService', null);
      this.set('offeredServiceAddOns', []);
      this.set('saving', false);
      this.set('dates', []);
      this.set('specialInstructions', null);
      this.set('visitsPerDay', 1);
      this.set('modifyingAppointments', []);
      this.set('modifyingAppointmentRequest', null);
      this.set('shownTapPreviousStepMessage', false);
      this.set('hasShownRequestSlideMessage', false);
      this.set('shownMultiDateMessage', false);
      this.set('redirectedFromMessage', false);
      this.set('hasSeenHolidaySurchargeModal', false);
      this.set('hasSeenWeekendSurchargeModal', false);
      this.set('hasSeenLateFeeSurchargeModal', false);
      this.resetAppointments();
    },
    holidaySurchargeDates: computed('dates.[]', 'offeredService.service_category', function () {
      var _this = this;

      if (!this.offeredService || !this.offeredService.get('service_category.holiday_surcharge_policy')) {
        return [];
      }

      var holidayDates = this.offeredService.get('service_category.holiday_surcharge_policy.dates');
      return holidayDates.filter(function (holidayDate) {
        return _this.dates.includes(holidayDate);
      });
    }),
    weekendSurchargeDates: computed('dates.[]', 'offeredService.service_category', 'holidaySurchargeDates.[]', function () {
      var _this2 = this;

      if (!this.offeredService.get('service_category.weekend_surcharge_policy')) {
        return [];
      }

      var daysOfWeek = this.offeredService.get('service_category.weekend_surcharge_policy.days_of_week');
      var weekendDates = this.dates.filter(function (date) {
        return daysOfWeek.includes(moment(date, 'YYYY-MM-DD').day());
      }); // Holiday surcharges take precedent over weekend surcharges

      return weekendDates.filter(function (date) {
        return !_this2.get('holidaySurchargeDates').includes(date);
      });
    }),
    lateBookingSurchargeAppointments: computed('appointments.@each.timeFrame', 'dates.[]', 'offeredService.service_category', function () {
      var _this3 = this;

      var policy = this.offeredService.get('service_category.late_booking_surcharge_policy');

      if (!policy) {
        return [];
      }

      var now = moment().tz(this.offeredService.get('company_time_zone'));
      var lateBookedAppts = [];
      this.dates.forEach(function (date) {
        _this3.appointments.forEach(function (appt) {
          var startTime = appt.get('timeFrame.startTime') || appt.get('timeFrame.time');
          var apptStartTime = moment.tz("".concat(date, " ").concat(startTime), 'YYYY-MM-DD h:mmA', _this3.offeredService.get('company_time_zone'));

          if (policy.get('method') == 'within') {
            apptStartTime.subtract(policy.get('within_minutes'), 'minutes');

            if (apptStartTime.isSameOrBefore(now)) {
              lateBookedAppts.pushObject({
                date: date,
                time: appt.get('timeFrame.startTime')
              });
            }
          } else {
            var _date = apptStartTime.subtract(policy.get('days_before'), 'days').format('YYYY-MM-DD');

            var afterMoment = moment.tz("".concat(_date, " ").concat(policy.get('time')), 'YYYY-MM-DD h:mmA', _this3.offeredService.get('company_time_zone'));

            if (now.isSameOrAfter(afterMoment)) {
              lateBookedAppts.pushObject({
                date: _date,
                time: appt.get('timeFrame.startTime')
              });
            }
          }
        });
      });
      return lateBookedAppts;
    }),
    offHoursSurchargeAppointments: computed('appointments.@each.timeFrame', 'dates.[]', 'offeredService.service_category', function () {
      var _this4 = this;

      var policy = this.offeredService.get('service_category.off_hours_surcharge_policy');

      if (!policy) {
        return [];
      }

      var offHoursAppts = [];
      this.dates.forEach(function (date) {
        var dayOfWeek = moment(date).day();

        _this4.appointments.forEach(function (appt) {
          policy.get('time_frames').forEach(function (timeFrame) {
            if (timeFrame.get('noSpacesStartTime') == appt.get('timeFrame.startTime') && timeFrame.get('noSpacesEndTime') == appt.get('timeFrame.endTime') && timeFrame.get('days_of_week').includes(dayOfWeek)) {
              offHoursAppts.pushObject({
                date: date,
                timeFrame: timeFrame
              });
            }
          });
        });
      });
      return offHoursAppts;
    })
  });

  _exports.default = _default;
});