define("frontend/routes/portal/appointment-request/date-selection", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route"], function (_exports, _appointmentRequest, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    media: inject.service(),
    currentUser: inject.service(),
    saving: computed.alias('appointmentRequestData.saving'),
    visitsPerDay: computed.alias('appointmentRequestData.visitsPerDay'),
    appointments: computed.alias('appointmentRequestData.appointments'),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var shouldSeeTapMessage = this.get('currentUser.user').shouldSeeMessage('request-tap-previous');
      var isNotMobile = !this.get('media.isMobile');
      controller.set('userCouldSeeTapPreviousStepMessage', shouldSeeTapMessage && isNotMobile);
    },
    deactivate: function deactivate() {
      this.set('controller.hasBeenShownMessageThisAppointment', false);
    },
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('selectedPets.length') === 0) {
        this.transitionTo('portal.appointment-request.pet-selection');
      }

      if (!this.get('offeredService')) {
        this.transitionTo('portal.appointment-request.service-selection');
      }
    }
  });

  _exports.default = _default;
});