define("frontend/components/ppb-password-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ppb-password-input'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('value')) {
        this.set('floatLabel', true);
      }
    },
    actions: {
      focusIn: function focusIn() {
        this.set('focused', true);
        this.set('floatLabel', true);
      },
      focusOut: function focusOut() {
        if (!this.get('value')) {
          this.set('floatLabel', false);
        }

        this.set('focused', false);
      }
    }
  });

  _exports.default = _default;
});