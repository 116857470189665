define("frontend/templates/components/appointment-request-time-frame-list/time-frame-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNaPOUEJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"timeFrame\",\"startTime\"]]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"timeFrame\",\"startTime\"]],false],[0,\" - \"],[1,[24,[\"timeFrame\",\"endTime\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[24,[\"timeFrame\",\"time\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-request-time-frame-list/time-frame-button.hbs"
    }
  });

  _exports.default = _default;
});