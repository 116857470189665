define("frontend/components/invoice-receipt/adjustments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['adjustments-list'],
    adjustmentsSorting: ['updated_at:desc'],
    sortedManualAdjustments: computed.sort('manualAdjustments', 'adjustmentsSorting'),
    multipleWeekendSurcharges: computed.gt('groupedWeekendSurcharges.length', 1),
    multipleOffHoursSurcharges: computed.gt('groupedOffHoursSurcharges.length', 1),
    multipleLateBookingSurcharges: computed.gt('groupedLateBookingSurcharges.length', 1),
    multipleHolidaySurcharges: computed.gt('groupedHolidaySurchargesholidaySurcharges.length', 1),
    multipleCancellationFees: computed.gt('cancellationFees.length', 1),
    indentAdjustments: computed.or('multipleWeekendSurcharges', 'multipleLateBookingSurcharges', 'multipleHolidaySurcharges', 'multipleOffHoursSurcharges', 'multipleCancellationFees'),
    manualAdjustments: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return !adjustment.get('surcharge_policy_type') && !adjustment.get('is_cancellation_fee') && !adjustment.get('is_sales_tax');
      });
    }),
    salesTaxAdjustment: computed('adjustments.[]', function () {
      var adjustments = this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('is_sales_tax');
      });
      return adjustments.get('firstObject');
    }),
    cancellationFees: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('is_cancellation_fee');
      });
    }),
    weekendSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isWeekendSurcharge');
      });
    }),
    offHoursSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isOffHoursSurcharge');
      });
    }),
    lateBookingSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isLateBookingSurcharge');
      });
    }),
    holidaySurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isHolidaySurcharge');
      });
    }),
    groupedCancellationFees: computed('cancellationFees.[]', function () {
      return this.groupSurcharges(this.get('cancellationFees'));
    }),
    groupedWeekendSurcharges: computed('weekendSurcharges.[]', function () {
      return this.groupSurcharges(this.get('weekendSurcharges'));
    }),
    groupedOffHoursSurcharges: computed('offHoursSurcharges.[]', function () {
      return this.groupSurcharges(this.get('offHoursSurcharges'));
    }),
    groupedLateBookingSurcharges: computed('lateBookingSurcharges.[]', function () {
      return this.groupSurcharges(this.get('lateBookingSurcharges'));
    }),
    groupedHolidaySurcharges: computed('holidaySurcharges.[]', function () {
      return this.groupSurcharges(this.get('holidaySurcharges'));
    }),
    groupSurcharges: function groupSurcharges(surcharges) {
      var groupedSurcharges = {};
      surcharges.forEach(function (surcharge) {
        var key = "".concat(surcharge.get('appointment_date'), " ").concat(surcharge.get('offered_service_name'));

        if (!groupedSurcharges[key]) {
          groupedSurcharges[key] = Ember.Object.create({
            appointment_date: surcharge.get('appointment_date'),
            appointment_sortable_date: surcharge.get('appointment_sortable_date'),
            categoryName: surcharge.get('appointment_category_name'),
            label: key,
            amount: 0,
            adjustments: [],
            selected: false
          });
        }

        groupedSurcharges[key].amount += parseFloat(surcharge.get('amount'));
        groupedSurcharges[key].adjustments.push(surcharge);
      });
      return Object.values(groupedSurcharges);
    }
  });

  _exports.default = _default;
});