define("frontend/controllers/portal/appointment-request/summary", ["exports", "frontend/config/environment", "frontend/utils/string-util", "frontend/mixins/dates-to-month-ranges", "jquery"], function (_exports, _environment, _stringUtil, _datesToMonthRanges, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend(_datesToMonthRanges.default, {
    saving: false,
    appointmentRequestData: inject.service(),
    appointments: computed.alias('appointmentRequestData.appointments'),
    pets: computed.alias('appointmentRequestData.selectedPets'),
    allPetsLength: computed.alias('appointmentRequestData.household.pets.length'),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    addOnServices: computed.alias('appointmentRequestData.offeredServiceAddOns'),
    dates: computed.alias('appointmentRequestData.dates'),
    specialInstructions: computed.alias('appointmentRequestData.specialInstructions'),
    modifyingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    hasExistingAppointments: computed.gt('modifyingAppointments.length', 0),
    modifyingAppointmentRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    hasExistingRequest: computed.notEmpty('modifyingAppointmentRequest'),
    isModifying: computed.or('hasExistingRequest', 'hasExistingAppointments'),
    dateText: computed('dates', function () {
      var dates = this.get('dates');

      if (dates.length == 1) {
        return moment(this.get('dates.firstObject')).format('MMMM D, YYYY');
      } else {
        return this.datesToMonthRanges(dates);
      }
    }),
    petNames: computed('pets.[]', function () {
      var pets = this.get('pets');
      return _stringUtil.default.joinWithAnd(pets.map(function (pet) {
        return pet.get('name');
      }));
    }),
    requestDetails: function requestDetails() {
      var _this = this;

      var time_frames = this.get('appointments').map(function (appt) {
        return {
          start_time: appt.get('timeFrame.startTime'),
          end_time: appt.get('timeFrame.endTime'),
          time: appt.get('timeFrame.time')
        };
      });
      var details = {
        special_instructions: this.get('specialInstructions'),
        appointments_to_modify: this.get('modifyingAppointments').map(function (appt) {
          return appt.get('id');
        }),
        appointments: []
      };
      var pet_ids = this.get('pets').map(function (pet) {
        return pet.get('id');
      });
      var add_on_services = this.get('addOnServices').map(function (addOn) {
        return {
          offered_service_id: addOn.get('offered_service.id'),
          pet_ids: addOn.get('petIds')
        };
      });
      this.get('dates').forEach(function (date) {
        time_frames.forEach(function (time_frame) {
          details['appointments'].push({
            pet_ids: pet_ids,
            add_on_services: add_on_services,
            offered_service_id: _this.get('offeredService.id'),
            time_frame: time_frame,
            date: date
          });
        });
      });

      if (this.get('hasExistingRequest')) {
        details.request_to_modify = this.get('modifyingAppointmentRequest.id');
      }

      return details;
    },
    actions: {
      goToPets: function goToPets() {
        if (this.get('allPetsLength') > 1) {
          this.transitionToRoute('portal.appointment-request.pet-selection');
        }
      },
      goToServices: function goToServices() {
        var serviceCategoryId = this.get('offeredService.service_category.id');
        this.transitionToRoute('portal.appointment-request.service-selection', {
          queryParams: {
            serviceCategoryId: serviceCategoryId
          }
        });
      },
      goToDateTimes: function goToDateTimes() {
        this.transitionToRoute('portal.appointment-request.date-selection');
      },
      checkCancellationFee: function checkCancellationFee() {
        var _this2 = this;

        if (this.get('hasExistingAppointments')) {
          this.set('saving', true);
          var details = this.requestDetails();

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/check_cancellation_fee"),
            method: 'GET',
            data: {
              details: details
            }
          }).then(function (response) {
            if (response.cancellation_policy.cancellation_fee > 0) {
              _this2.set('showCancellationFeeModal', true);

              _this2.set('cancellationPolicy', response.cancellation_policy);

              _this2.set('companyName', response.company_name);

              _this2.set('saving', false);
            } else {
              _this2.send('requestAppointments');
            }
          });
        } else {
          this.send('requestAppointments');
        }
      },
      confirmCancellationFee: function confirmCancellationFee() {
        this.set('showCancellationFeeModal', false);
        this.set('cancellationPolicy', null);
        this.send('requestAppointments');
      },
      closeCancellationFeeModal: function closeCancellationFeeModal() {
        this.set('showCancellationFeeModal', false);
        this.set('cancellationPolicy', null);
      },
      requestAppointments: function requestAppointments() {
        var _this3 = this;

        var details = this.requestDetails();
        this.set('saving', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests"),
          method: 'POST',
          data: {
            details: details
          }
        }).then(function (response) {
          _this3.set('saving', false);

          _this3.set('companyLogo', response.company_logo);

          _this3.set('companyName', response.company_name);

          _this3.set('showSuccessModal', true);
        });
      },
      goToAppointments: function goToAppointments() {
        var _this4 = this;

        this.transitionToRoute('portal.appointments', {
          queryParams: {
            appointmentType: 'pending'
          }
        }).then(function () {
          _this4.set('showSuccessModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});