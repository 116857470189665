define("frontend/components/appointment-request-time-frame-list/time-frame-button", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['time-frame-button'],
    classNameBindings: ['selected', 'timeFrame.disabled:disabled'],
    timeFrame: null,
    appointment: null,
    didRender: function didRender() {
      if (this.get('timeFrame.disabled')) {
        var elementId = this.get('elementId');
        (0, _jquery.default)("#".concat(elementId)).tooltip('hide').attr('data-original-title', this.get('tooltipText'));
        (0, _jquery.default)(document).on('mouseenter', "#".concat(elementId), function () {
          (0, _jquery.default)("#".concat(elementId)).tooltip('show');
        });
      }
    },
    selected: computed('timeFrame.startTime', 'appointment.timeFrame.startTime', 'appointment.timeFrame.endTime', 'timeFrame.endTime', function () {
      if (this.get('timeFrame.startTime')) {
        return this.get('appointment.timeFrame.startTime') == this.get('timeFrame.startTime') && this.get('appointment.timeFrame.endTime') == this.get('timeFrame.endTime');
      } else {
        return this.get('appointment.timeFrame.time') == this.get('timeFrame.time');
      }
    }),
    tooltipText: computed('timeFrame.startTime', function () {
      var windowText = this.get('timeFrame.startTime') ? ' window' : '';
      return "This time".concat(windowText, " is unavailable!");
    }),
    click: function click() {
      if (!this.get('timeFrame.disabled')) {
        this.get('clickTimeFrame')(this.get('timeFrame'), this.get('appointment'));
      }
    }
  });

  _exports.default = _default;
});