define("frontend/controllers/portal/appointment-request", ["exports", "frontend/mixins/appointment-request"], function (_exports, _appointmentRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend(_appointmentRequest.default, {
    media: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    saving: computed.readOnly('appointmentRequestData.saving'),
    modifyingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    hasExistingAppointments: computed.gt('modifyingAppointments.length', 0),
    modifyingAppointmentRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    hasExistingRequest: computed.notEmpty('modifyingAppointmentRequest'),
    isModifying: computed.or('hasExistingRequest', 'hasExistingAppointments'),
    hasClose: computed('media.isMobile', 'saving', function () {
      var isNotMobile = !this.get('media.isMobile');
      var notSaving = !this.get('saving');
      return isNotMobile && notSaving;
    }),
    actions: {
      goToPetSelection: function goToPetSelection() {
        this.transitionToRoute('portal.appointment-request.pet-selection');
      },
      goToServiceSelection: function goToServiceSelection() {
        this.transitionToRoute('portal.appointment-request.service-selection');
      },
      goToDateSelection: function goToDateSelection() {
        this.transitionToRoute('portal.appointment-request.date-selection');
      },
      goToTimeSelection: function goToTimeSelection() {
        this.transitionToRoute('portal.appointment-request.time-selection');
      },
      goToSummary: function goToSummary() {
        this.transitionToRoute('portal.appointment-request.summary');
      }
    }
  });

  _exports.default = _default;
});