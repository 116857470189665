define("frontend/services/duplicate-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Service.extend({
    ajax: inject.service(),
    check: function check(emailAddress, household) {
      var url = "/api/v2/email/duplicate_check?email=".concat(encodeURIComponent(emailAddress));

      if (household && household.get('id')) {
        url += "&household=".concat(household.get('id'));
      }

      return this.get('ajax').request(url);
    }
  });

  _exports.default = _default;
});