define("frontend/routes/portal/appointment-request/time-selection", ["exports", "frontend/mixins/appointment-request", "frontend/routes/base-route", "jquery"], function (_exports, _appointmentRequest, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _baseRoute.default.extend(_appointmentRequest.default, {
    visitsPerDay: computed.alias('appointmentRequestData.visitsPerDay'),
    appointments: computed.alias('appointmentRequestData.appointments'),
    dates: computed.alias('appointmentRequestData.dates'),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    selectedPets: computed.alias('appointmentRequestData.selectedPets'),
    model: function model() {
      var dates = this.get('dates');
      var offeredServiceId = this.get('offeredService.id');
      var selectedPetsNum = this.get('selectedPets.length');

      if (dates && offeredServiceId) {
        return _jquery.default.ajax({
          url: "/api/v2/time_frames/appointment_request?dates=".concat(dates, "&offered_service_id=").concat(offeredServiceId, "&pets=").concat(selectedPetsNum),
          method: 'GET'
        });
      }
    },
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('selectedPets.length') === 0) {
        this.transitionTo('portal.appointment-request.pet-selection');
      }

      if (!this.get('offeredService')) {
        this.transitionTo('portal.appointment-request.service-selection');
      }

      if (!this.get('dates')) {
        this.transitionTo('portal.appointment-request.date-selection');
      }
    }
  });

  _exports.default = _default;
});