define("frontend/templates/components/appointment-request-time-frame-tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/D/uBAp",
    "block": "{\"symbols\":[\"appointment\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"appointments\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"appointment-request-time-frame-tab\",null,[[\"index\",\"appointmentsLength\",\"appointment\",\"timeWindowInMinutes\",\"hasTimeFrames\",\"timeFrames\",\"timeFramesWithExisting\",\"morningWindows\",\"afternoonWindows\",\"eveningWindows\",\"openTab\",\"clickTimeFrame\",\"removeAppointment\"],[[23,2,[]],[24,[\"appointments\",\"length\"]],[23,1,[]],[24,[\"timeWindowInMinutes\"]],[24,[\"hasTimeFrames\"]],[24,[\"timeFrames\"]],[24,[\"timeFramesWithExisting\"]],[24,[\"morningWindows\"]],[24,[\"afternoonWindows\"]],[24,[\"eveningWindows\"]],[28,\"action\",[[23,0,[]],\"openTab\"],null],[28,\"action\",[[23,0,[]],\"clickTimeFrame\"],null],[28,\"action\",[[23,0,[]],\"removeAppointment\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-request-time-frame-tab-list.hbs"
    }
  });

  _exports.default = _default;
});