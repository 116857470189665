define("frontend/components/portal-booking-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['portal-booking-navigation'],
    classNameBindings: ['showPetButton:has-pet-selection'],
    appointmentRequestData: null,
    currentRouteName: null,
    onPetSelection: computed.equal('currentRouteName', 'portal.appointment-request.pet-selection'),
    onServiceSelection: computed.equal('currentRouteName', 'portal.appointment-request.service-selection'),
    onDateSelection: computed.equal('currentRouteName', 'portal.appointment-request.date-selection'),
    onTimeSelection: computed.equal('currentRouteName', 'portal.appointment-request.time-selection'),
    onSummary: computed.equal('currentRouteName', 'portal.appointment-request.summary'),
    petButtonFilled: computed.alias('appointmentRequestData.hasSelectedPets'),
    servicesButtonEnabled: computed.alias('petButtonFilled'),
    servicesButtonFilled: computed.notEmpty('appointmentRequestData.offeredService'),
    dateButtonEnabled: computed.alias('servicesButtonFilled'),
    dateButtonFilled: computed.alias('appointmentRequestData.hasSelectedDate'),
    timeButtonEnabled: computed.alias('dateButtonFilled'),
    timeButtonFilled: computed.alias('appointmentRequestData.hasSelectedTimes'),
    summaryButtonEnabled: computed.alias('timeButtonFilled'),
    showPetButton: computed('appointmentRequestData.household.pets.[]', function () {
      return this.get('appointmentRequestData.household.pets.length') > 1;
    }),
    actions: {
      goToPetSelection: function goToPetSelection() {
        this.get('goToPetSelection')();
      },
      goToServiceSelection: function goToServiceSelection() {
        if (this.get('servicesButtonEnabled')) {
          this.get('goToServiceSelection')();
        }
      },
      goToDateSelection: function goToDateSelection() {
        if (this.get('dateButtonEnabled')) {
          this.get('goToDateSelection')();
        }
      },
      goToTimeSelection: function goToTimeSelection() {
        if (this.get('timeButtonEnabled')) {
          this.get('goToTimeSelection')();
        }
      },
      goToSummary: function goToSummary() {
        if (this.get('summaryButtonEnabled')) {
          this.get('goToSummary')();
        }
      }
    }
  });

  _exports.default = _default;
});