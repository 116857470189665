define("frontend/components/offered-service-add-on", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['offered-service-add-on'],
    classNameBindings: ['selected', 'disabled'],
    media: inject.service(),
    offeredService: null,
    petsSelectedLength: 0,
    descriptionExpanded: false,
    showPrices: true,
    selectedAddOnServices: [],
    click: function click() {
      if (this.get('enabled')) {
        this.get('clickAddOn')(this.get('offeredService'));
      }
    },
    selectedAddOnServiceIds: computed.mapBy('selectedAddOnServices', 'offered_service.id'),
    selected: computed('selectedAddOnServiceIds.[]', function () {
      return this.get('selectedAddOnServiceIds').includes(this.get('offeredService.id'));
    }),
    enabled: computed.alias('offeredService.show_to_pet_parents'),
    disabled: computed.not('enabled'),
    disabledTooltipText: computed('disabled', function () {
      return this.get('disabled') ? 'Sorry! Your care provider does not allow modifications for this add-on online. Please message them directly to request your change!' : '';
    }),
    selectedAdditionalText: computed('selectedAddOnServices.[]', 'selected', function () {
      if (!this.get('selected')) {
        return;
      }

      var addOnService = this.get('selectedAddOnServices').findBy('offered_service.id', this.get('offeredService.id'));

      if (addOnService.petIds.length == this.get('petsSelectedLength')) {
        return;
      } else {
        var petNames = _stringUtil.default.joinWithAnd(addOnService.petNames);

        return " <span class=\"add-on-service-for-pets\">for <span class=\"bold\">".concat(petNames, "</span></span>");
      }
    }),
    maxDescriptionLength: computed('media.isMobile', function () {
      if (this.get('media.isMobile')) {
        return 90;
      } else {
        return 130;
      }
    }),
    hasLongDescription: computed('maxDescriptionLength', 'offeredService.description', function () {
      return this.get('offeredService.description.length') > this.get('maxDescriptionLength');
    }),
    price: computed.alias('offeredService.latest_price'),
    incrementalPrice: computed.alias('offeredService.latest_incremental_price'),
    hasIncrementalPrice: computed('price', 'incrementalPrice', function () {
      return this.get('price') != this.get('incrementalPrice');
    }),
    pricePerVisit: computed('petsSelectedLength', 'price', 'incrementalPrice', function () {
      return this.get('price') + (this.get('petsSelectedLength') - 1) * this.get('incrementalPrice');
    }),
    actions: {
      toggleDescriptionExpansion: function toggleDescriptionExpansion() {
        this.toggleProperty('descriptionExpanded');
      }
    }
  });

  _exports.default = _default;
});