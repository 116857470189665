define("frontend/components/appointment-request-time-frame-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNameBindings: ['swipedLeft'],
    media: inject.service(),
    headerAction: computed('media.isMobile', function () {
      return this.get('media.isMobile') ? 'clickTab' : 'openTab';
    }),
    showModifyButton: computed.and('media.isMobile', 'appointment.collapsed'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var appt = this.get('appointment');
      var enabledMorningWindows = this.get('morningWindows').filter(function (window) {
        return !window.get('disabled');
      });

      if (enabledMorningWindows.length == 0) {
        appt.set('timePeriodChoice', 'afternoon');
      }

      var enabledAfternoonWindows = this.get('afternoonWindows').filter(function (window) {
        return !window.get('disabled');
      });

      if (enabledAfternoonWindows.length == 0) {
        appt.set('timePeriodChoice', 'evening');
      }
    },
    appointmentsLengthObserver: observer('appointmentsLength', function () {
      if (this.get('appointmentsLength') == 1) {
        this.set('swipedLeft', false);
      }
    }),
    numberOfChoices: computed('morningWindows.[]', 'afternoonWindows.[]', function () {
      if (this.get('morningWindows.length') > 0) {
        return 'three';
      }

      if (this.get('afternoonWindows.length') > 0) {
        return 'two';
      }

      return 'one';
    }),
    allWindows: computed('morningWindows.[]', 'afternoonWindows.[]', 'eveningWindows.[]', function () {
      var morning = this.get('morningWindows');
      var afternoon = this.get('afternoonWindows');
      var evening = this.get('eveningWindows');
      return morning.concat(afternoon).concat(evening);
    }),
    label: computed('appointment.label', function () {
      if (this.get('appointment.label') == 'PREFERRED ARRIVAL WINDOW' && this.get('timeOrWindow') == 'time') {
        return 'PREFERRED ARRIVAL TIME';
      }

      return this.get('appointment.label');
    }),
    timeOrWindow: computed('hasTimeFrames', 'timeWindowInMinutes', function () {
      if (!this.get('hasTimeFrames') && this.get('timeWindowInMinutes') == 0) {
        return 'time';
      } else {
        return 'window';
      }
    }),
    actions: {
      openTab: function openTab(appointment) {
        this.set('swipedLeft', false);
        this.get('openTab')(appointment);
      },
      clickTimeFrame: function clickTimeFrame(timeFrame, appointment) {
        this.get('clickTimeFrame')(timeFrame, appointment);
      },
      clickTab: function clickTab() {
        if (this.get('media.isMobile') && this.get('appointmentsLength') > 1 && this.get('appointment.timeFrame')) {
          this.toggleProperty('swipedLeft');
        }
      },
      removeAppointment: function removeAppointment(appointment) {
        this.get('removeAppointment')(appointment);
      }
    }
  });

  _exports.default = _default;
});