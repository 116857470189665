define("frontend/components/sign-contracts-modal", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject;

  var redirectUserTo = function redirectUserTo(path) {
    var _window$location = window.location,
        hostname = _window$location.hostname,
        origin = _window$location.origin;
    var url = "".concat(origin).concat(path);

    if (hostname.includes("localhost")) {
      url = "http://".concat(hostname, ":3001").concat(path);
    }

    window.location.replace(url);
  };

  var _default = Ember.Component.extend({
    media: inject.service(),
    isMobile: computed.alias('media.isMobile'),
    signatureRequests: [],
    companyName: computed.alias('signatureRequests.firstObject.company'),
    signatureUrl: computed.alias('signatureRequests.firstObject.signature_url'),
    currentSignatureRequest: null,
    pdfURL: computed.alias('currentSignatureRequest.pdf_document_url'),
    pdfURLForMobile: computed('pdfURL', function () {
      var encodedUrl = encodeURIComponent(this.get('pdfURL'));
      return "https://docs.google.com/viewer?url=".concat(encodedUrl, "&embedded=true");
    }),
    urlForIFrame: computed('isMobile', 'pdfURL', 'pdfURLForMobile', function () {
      return this.get('isMobile') ? this.get('pdfURLForMobile') : this.get('pdfURL');
    }),
    signatureName: null,
    signing: false,
    attemptedSign: false,
    vetLimitAmount: null,
    signContractParam: null,
    householdId: null,
    loadingIframe: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('signContractParam') && this.get('isMobile')) {
        setTimeout(function () {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }
    },
    observeCurrentSignatureRequest: observer('currentSignatureRequest', function () {
      if (this.get('currentSignatureRequest')) {
        var urlForIFrame = this.get('urlForIFrame');
        var pdfURL = this.get('pdfURL');
        this.set('loadingIframe', true);

        if (urlForIFrame && pdfURL) {
          (0, _jquery.default)('#iframeContainer').empty();
          (0, _jquery.default)('.iframe-loader').css('display', 'block');

          var loadIFrame = function loadIFrame() {
            var stuff = document.evaluate('//*[@id="myIframe"]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            if (stuff && stuff.contentWindow && stuff.contentWindow.length) {
              (0, _jquery.default)('.iframe-loader').css('display', 'none');
              clearInterval(loadIFrameInterval);
            } else {
              var iframe = "<iframe id=\"myIframe\" src=\"".concat(urlForIFrame, "\" style=\"width:100%; height:100%; border: none;\"></iframe>");
              (0, _jquery.default)('#iframeContainer').html(iframe);
              count++;
            }
          };

          var count = 0;
          var loadIFrameInterval = setInterval(loadIFrame, 2000);

          if (count > 5) {
            clearInterval(loadIFrameInterval);
            (0, _jquery.default)('.iframe-loader').css('display', 'none');
          }
        }
      }
    }),
    signContractParamObserver: observer('signContractParam', function () {
      if (this.get('signContractParam') && this.get('isMobile')) {
        setTimeout(function () {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }
    }),
    companyNames: computed('signatureRequests.@each.company', function () {
      var companies = this.get('signatureRequests').mapBy('company');

      var uniqueCompanies = _toConsumableArray(new Set(companies));

      return _stringUtil.default.joinWithAnd(uniqueCompanies);
    }),
    vetAmountErrorObserver: observer('vetLimitAmount', 'attemptedSign', function () {
      var show = this.get('attemptedSign') && (this.get('vetLimitAmount') == null || this.get('vetLimitAmount') <= 0);
      this.set('showVetAmountError', show);
    }),
    actions: {
      viewDocument: function viewDocument() {
        var url = this.get('pdfURL');

        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      },
      close: function close() {
        this.get('onClose')();
      },
      toggleVetLimitSwitch: function toggleVetLimitSwitch() {
        this.toggleProperty('vetLimitEnabled');

        if (!this.get('vetLimitEnabled')) {
          this.set('vetLimitAmount', null);
        }
      },
      openSignatureRequest: function openSignatureRequest() {
        this.set('currentSignatureRequest', this.get('signatureRequests.firstObject'));
      },
      signDocument: function signDocument() {
        var _this = this;

        var agreedToRelease = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.get('signing')) {
          return;
        }

        this.set('attemptedSign', true);
        var index = this.get('signatureRequests').indexOf(this.get('currentSignatureRequest'));
        this.set('signing', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/sign_contract",
          method: 'POST',
          data: {
            signature_request_id: this.get('currentSignatureRequest.id'),
            signature_name: this.get('signatureName'),
            agreed_to_release: agreedToRelease,
            vet_limit_amount: this.get('vetLimitAmount')
          }
        }).then(function () {
          _this.set('signing', false);

          _this.set('attemptedSign', false);

          if (index < _this.get('signatureRequests.length') - 1) {
            _this.set('currentSignatureRequest', _this.get('signatureRequests').objectAt(index + 1));

            _this.set('signatureName', null);
          } else {
            var signContractParam = _this.get("signContractParam");

            var householdId = _this.get("householdId");

            if (signContractParam) {
              var reactPortalDocsPath = "/react/portal/".concat(householdId, "/documents");
              redirectUserTo(reactPortalDocsPath);
            } else {
              window.location.reload();
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});