define("frontend/controllers/portal/appointment-request/date-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    appointmentRequestData: inject.service(),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    dates: computed.alias('appointmentRequestData.dates'),
    appointments: computed.alias('appointmentRequestData.appointments'),
    existingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    hasExistingAppointments: computed.notEmpty('existingAppointments'),
    existingRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    hasExistingRequest: computed.notEmpty('existingRequest'),
    isModifying: computed.or('hasExistingRequest', 'hasExistingAppointments'),
    hasSeenHolidaySurchargeModal: computed.alias('appointmentRequestData.hasSeenHolidaySurchargeModal'),
    hasSeenWeekendSurchargeModal: computed.alias('appointmentRequestData.hasSeenWeekendSurchargeModal'),
    timeZone: computed.alias('offeredService.company_time_zone'),
    transitioningToNext: false,
    userCouldSeeTapPreviousStepMessage: false,
    showTapPreviousStepMessage: computed('userCouldSeeTapPreviousStepMessage', 'appointmentRequestData.shownTapPreviousStepMessage', function () {
      return this.get('userCouldSeeTapPreviousStepMessage') && !this.get('appointmentRequestData.shownTapPreviousStepMessage');
    }),
    disabledDays: computed('offeredService.service_category', function () {
      var fullDays = [0, 1, 2, 3, 4, 5, 6];

      var daysAvailable = _toConsumableArray(this.get('offeredService.service_category.numberedWeekdays'));

      if (daysAvailable.length == 0) {
        return [];
      }

      return fullDays.filter(function (day) {
        return !daysAvailable.includes(day);
      });
    }),
    scrollToButton: function scrollToButton() {
      if (this.get('dates.length') == 0) {
        Ember.run.scheduleOnce('afterRender', function () {
          var ele = document.getElementById('proceed-button');

          if (ele) {
            ele.scrollIntoViewIfNeeded(true);
          }
        });
      }
    },
    calendarStartDate: computed('offeredService.service_category', 'timeZone', 'offeredService.service_category.request_cut_off_policy', function () {
      var tz = this.get('timeZone');
      var timeFrames = this.get('offeredService.service_category.time_frames');
      var cutOffPolicy = this.get('offeredService.service_category.request_cut_off_policy');
      var cutOffTime = moment.tz(tz);

      if (cutOffPolicy) {
        if (cutOffPolicy.get('method') == 'after') {
          var day = moment.tz(tz).add(cutOffPolicy.get('days_before'), 'day');
          cutOffTime = moment.tz("".concat(moment.tz(tz).format('YYYY-MM-DD'), " ").concat(cutOffPolicy.get('time')), 'YYYY-MM-DD h:mm A', tz);

          if (cutOffTime.isBefore(moment.tz(tz))) {
            return day.add(1, 'day').format('YYYY-MM-DD');
          } else {
            return day.format('YYYY-MM-DD');
          }
        } else {
          cutOffTime.add(cutOffPolicy.get('within_minutes'), 'minute');
        }
      }

      if (timeFrames.get('length') > 0) {
        var cutOffDayHasTimeFrames = false;
        timeFrames.forEach(function (timeFrame) {
          if (timeFrame.get('days_of_week').includes(moment.tz(tz).day())) {
            var endTime = moment.tz("".concat(cutOffTime.format('YYYY-MM-DD'), " ").concat(timeFrame.get('end_time')), 'YYYY-MM-DD h:mm A', tz);

            if (endTime.isAfter(cutOffTime)) {
              cutOffDayHasTimeFrames = true;
            }
          }
        });

        if (!cutOffDayHasTimeFrames) {
          return cutOffTime.add(1, 'day').format('YYYY-MM-DD');
        } else {
          return cutOffTime.format('YYYY-MM-DD');
        }
      } else {
        var cutOff = moment.tz("".concat(cutOffTime.format('YYYY-MM-DD'), " 8:00 PM"), 'YYYY-MM-DD h:mm A', tz);

        if (cutOff.isAfter(cutOffTime)) {
          return cutOffTime.format('YYYY-MM-DD');
        } else {
          return cutOffTime.add(1, 'day').format('YYYY-MM-DD');
        }
      }
    }),
    validTimeWindows: computed('offeredService.service_category.time_frames.[]', 'dates.[]', 'timeZone', function () {
      var tz = this.get('timeZone');
      var dates = this.get('dates');

      if (dates.length > 0) {
        var days = dates.map(function (date) {
          return moment.tz(date, tz).day();
        });
        var dowTimeFrames = this.get('offeredService.service_category.time_frames').filter(function (timeFrame) {
          var isValid = true;
          days.forEach(function (day) {
            isValid = isValid && timeFrame.get('days_of_week').includes(day);
          });
          return isValid;
        });
        return dowTimeFrames.map(function (tf) {
          return Ember.Object.create({
            startTime: tf.get('noSpacesStartTime'),
            endTime: tf.get('noSpacesEndTime')
          });
        });
      }
    }),
    actions: {
      changeDates: function changeDates(dateArray) {
        var formattedDates = dateArray.map(function (date) {
          return moment(date).format('YYYY-MM-DD');
        });
        this.scrollToButton();
        this.set('dates', formattedDates);

        if (this.get('dates.length') == 1) {
          if (this.get('currentUser.user').shouldSeeMessage('request-multi-date') && !this.get('appointmentRequestData.shownMultiDateMessage')) {
            this.set('showMultiDateMessage', true);
          }
        }
      },
      changeSingularDate: function changeSingularDate(momentDate) {
        var newDate = momentDate.format('YYYY-MM-DD');
        this.scrollToButton();
        this.set('dates', [newDate]);
      },
      previousPage: function previousPage() {
        this.transitionToRoute('portal.appointment-request.service-selection');
      },
      nextPage: function nextPage() {
        var _this = this;

        var holidayDates = this.get('appointmentRequestData.holidaySurchargeDates');
        var weekendDates = this.get('appointmentRequestData.weekendSurchargeDates');

        if (holidayDates.length > 0 && !this.get('hasSeenHolidaySurchargeModal')) {
          this.set('hasSeenHolidaySurchargeModal', true);
          this.set('showHolidaySurchargeModal', true);
        } else if (weekendDates.length > 0 && !this.get('hasSeenWeekendSurchargeModal')) {
          this.set('hasSeenWeekendSurchargeModal', true);
          this.set('showWeekendSurchargeModal', true);
        } else {
          this.set('appointmentRequestData.hasSelectedDate', true);
          this.set('transitioningToNext', true);
          this.transitionToRoute('portal.appointment-request.time-selection').then(function () {
            _this.set('transitioningToNext', false);
          });
        }
      },
      closeHolidayPolicyModal: function closeHolidayPolicyModal() {
        this.set('showHolidaySurchargeModal', false);

        if (this.get('validTimeWindows').length == 1) {
          this.send('goToSummary');
        } else {
          this.send('nextPage');
        }
      },
      closeWeekendPolicyModal: function closeWeekendPolicyModal() {
        this.set('showWeekendSurchargeModal', false);

        if (this.get('validTimeWindows').length == 1) {
          this.send('goToSummary');
        } else {
          this.send('nextPage');
        }
      },
      hideTapPreviousStepMessage: function hideTapPreviousStepMessage() {
        this.set('appointmentRequestData.shownTapPreviousStepMessage', true);
      },
      dismissedTapPreviousStepMessage: function dismissedTapPreviousStepMessage() {
        this.send('hideTapPreviousStepMessage');
        this.get('currentUser.user').reload();
      },
      hideMultiDateMessage: function hideMultiDateMessage() {
        this.set('appointmentRequestData.shownMultiDateMessage', true);
        this.set('showMultiDateMessage', false);
      },
      dismissedMultiDateMessage: function dismissedMultiDateMessage() {
        this.send('hideMultiDateMessage');
        this.get('currentUser.user').reload();
      },
      goToSummary: function goToSummary() {
        var holidayDates = this.get('appointmentRequestData.holidaySurchargeDates');
        var weekendDates = this.get('appointmentRequestData.weekendSurchargeDates');

        if (holidayDates.length > 0 && !this.get('hasSeenHolidaySurchargeModal')) {
          this.set('hasSeenHolidaySurchargeModal', true);
          this.set('showHolidaySurchargeModal', true);
        } else if (weekendDates.length > 0 && !this.get('hasSeenWeekendSurchargeModal')) {
          this.set('hasSeenWeekendSurchargeModal', true);
          this.set('showWeekendSurchargeModal', true);
        } else {
          this.set('appointmentRequestData.hasSelectedDate', true);
          this.set('appointmentRequestData.hasSelectedTimes', true);
          this.set('appointments.firstObject.timeFrame', this.get('validTimeWindows.firstObject'));
          this.transitionToRoute('portal.appointment-request.summary', {
            queryParams: {
              ids: null,
              requestId: null
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});