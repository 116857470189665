define("frontend/components/weekend-surcharge-list-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    offeredService: null,
    policy: computed.alias('offeredService.service_category.weekend_surcharge_policy'),
    surchargeText: computed('offeredService.service_category.weekend_surcharge_policy', function () {
      var policy = this.get('offeredService.service_category.weekend_surcharge_policy');

      if (policy.get('amount_type') == 'fixed') {
        return "".concat(this.formatMoney(policy.get('amount')), " per ").concat(policy.get('charge_per_day') ? 'day' : 'visit');
      } else {
        return "".concat(policy.get('amount'), "%");
      }
    }),
    actions: {
      close: function close() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});