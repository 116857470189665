define("frontend/controllers/companies/category-settings", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    queryParams: ['categoryId', 'showHolidayPolicyModal'],
    currentUser: inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    flashMessage: inject.service(),
    firestore: inject.service(),
    firestoreListener: null,
    categoryId: null,
    showTimeBlockModal: false,
    selectedTimeFrame: null,
    onTab: null,
    onTimeBlocks: computed.equal('onTab', 'time-blocks'),
    onPolicies: computed.equal('onTab', 'policies'),
    onAccountability: computed.equal('onTab', 'accountability'),
    hasTimeFrames: computed.gt('selectedCategory.time_frames.length', 0),
    showAccountability: computed.or('selectedCategory.supports_report_cards', 'selectedCategory.supports_gps', 'selectedCategory.supports_check_in_out'),
    timeBlockLinkClasses: computed('onTimeBlocks', function () {
      if (this.get('onTimeBlocks')) {
        return 'header-link active';
      }

      return 'header-link';
    }),
    policiesLinkClasses: computed('onPolicies', function () {
      if (this.get('onPolicies')) {
        return 'header-link active';
      }

      return 'header-link';
    }),
    windowOptions: [{
      label: 'fifteen-minute',
      value: '15',
      window: 'thirty-minute'
    }, {
      label: 'thirty-minute',
      value: '30',
      window: 'one-hour'
    }, {
      label: 'forty five-minute',
      value: '45',
      window: 'one-hour and thirty-minute'
    }, {
      label: 'one-hour',
      value: '60',
      window: 'two-hour'
    }, {
      label: 'one-hour and thirty-minute',
      value: '90',
      window: 'three-hour'
    }, {
      label: 'two-hour',
      value: '120',
      window: 'four-hour'
    }, {
      label: 'two-hour and thirty-minute',
      value: '150',
      window: 'five-hour'
    }, {
      label: 'three-hour',
      value: '180',
      window: 'six-hour'
    }],
    selectedCategory: computed('categoryId', 'model.[]', function () {
      var _this = this;

      var selectedCategory;

      if (this.get('categoryId')) {
        selectedCategory = this.get('model').filter(function (category) {
          return category.get('id') == _this.get('categoryId');
        }).get('firstObject');
      } else {
        selectedCategory = this.get('model.firstObject');
      }

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      var self = this;
      var collection = this.get('firestore').getCollection("".concat(_environment.default.environment, "_time_frames"));

      if (collection) {
        var firestoreListener = collection.doc("".concat(this.get('company.id'), "_").concat(selectedCategory.get('id'))).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set('isUpdating', doc.data().updating);
          } else {
            self.set('isUpdating', false);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }

      return selectedCategory;
    }),
    selectedTimeWindow: computed('selectedCategory', 'selectedCategory.time_window_in_minutes', function () {
      var _this2 = this;

      var options = this.get('windowOptions');
      var selected = options.filter(function (option) {
        return _this2.get('selectedCategory.time_window_in_minutes') == option.value;
      });
      return selected.get('firstObject');
    }),
    categoryOptions: computed('model.[]', function () {
      return this.get('model').map(function (category) {
        return {
          label: category.get('name'),
          value: category.get('id')
        };
      });
    }),
    selectedCategoryOption: computed('categoryOptions.[]', 'selectedCategory', function () {
      var _this3 = this;

      return this.get('categoryOptions').filter(function (option) {
        return option.value == _this3.get('selectedCategory.id');
      }).get('firstObject');
    }),
    timeWindowClarification: computed('selectedTimeWindow', function () {
      var timeWindow = this.get('selectedTimeWindow');

      if (timeWindow) {
        var startTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').subtract(timeWindow.value, 'minutes').format('h:mmA');
        var endTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').add(timeWindow.value, 'minutes').format('h:mmA');
        return "".concat(startTime, " - ").concat(endTime);
      } else {
        return;
      }
    }),
    sortedCancellationFeePolicies: computed('selectedCategory', 'selectedCategory.cancellation_fee_policies.[]', 'selectedCategory.cancellation_fee_policies.@each.{time,withinMinutes,method,days_before,isDeleted}', function () {
      var policies = this.get('selectedCategory.cancellation_fee_policies').filter(function (policy) {
        return !policy.get('isDeleted');
      });
      return policies.sort(function (x, y) {
        if (x.get('method') == 'after' && y.get('method') == 'within') {
          return -1;
        }

        if (x.get('method') == 'within' && y.get('method') == 'after') {
          return 1;
        }

        if (x.get('method') == 'after') {
          if (x.get('days_before') < y.get('days_before')) {
            return -1;
          } else if (x.get('days_before') > y.get('days_before')) {
            return 1;
          } else {
            if (moment(x.get('time')) < moment(y.get('time'))) {
              return 1;
            }

            return -1;
          }
        } else {
          if (x.get('within_minutes') < y.get('within_minutes')) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    }),
    checkInReminderToggleActivated: computed('selectedCategory.checkInAlertTimeInMinutes', function () {
      return this.get('selectedCategory.checkInAlertTimeInMinutes') > 0;
    }),
    checkOutReminderToggleActivated: computed('selectedCategory.checkOutAlertTimeInMinutes', function () {
      return this.get('selectedCategory.checkOutAlertTimeInMinutes') > 0;
    }),
    timingOptions: [{
      label: '15 minutes',
      value: '15'
    }, {
      label: '30 minutes',
      value: '30'
    }, {
      label: '45 minutes',
      value: '45'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '2 hours',
      value: '120'
    }],
    selectedCheckInAlertTiming: computed('selectedCategory.checkInAlertTimeInMinutes', function () {
      var _this4 = this;

      return this.get('timingOptions').filter(function (option) {
        return option.value == _this4.get('selectedCategory.checkInAlertTimeInMinutes');
      }).get('firstObject');
    }),
    selectedCheckOutAlertTiming: computed('selectedCategory.checkOutAlertTimeInMinutes', function () {
      var _this5 = this;

      return this.get('timingOptions').filter(function (option) {
        return option.value == _this5.get('selectedCategory.checkOutAlertTimeInMinutes');
      }).get('firstObject');
    }),
    lateArrivalTooltip: "Get an alert if you or your team haven't started a visit within a certain time period of the scheduled appointment time. No late arrival alerts are sent for all-day services.",
    lateCheckoutTooltip: "Get an alert if you or your team haven't ended a visit within a certain time period after the appointment should have ended. No late check out alerts are sent for all-day services or services with a 0-minute duration.",
    actions: {
      toggleSelectingCategories: function toggleSelectingCategories() {
        this.toggleProperty('selectingCategories');
      },
      selectCategory: function selectCategory(category) {
        this.set('categoryId', category.get('id'));
        this.set('showUpdatingError', false);
      },
      updateSelectedCategory: function updateSelectedCategory(categoryId) {
        this.set('categoryId', categoryId);
        this.set('showUpdatingError', false);
      },
      clickUpdateHoursButton: function clickUpdateHoursButton() {
        if (this.get('isUpdating')) {
          this.set('showUpdatingError', true);
        } else {
          this.set('showUpdatingTimeFramesModal', true);
        }
      },
      closeUpdatingTimeFramesModal: function closeUpdatingTimeFramesModal() {
        this.get('selectedCategory').rollbackAttributes();
        this.get('selectedCategory.time_frames').filterBy('markedForDeletion', true).forEach(function (a) {
          return a.set('markedForDeletion', false);
        });
        this.get('selectedCategory.time_frames').forEach(function (timeFrame) {
          timeFrame.rollbackAttributes();
        });
        this.set('showUpdatingTimeFramesModal', false);
      },
      updateCategoryHours: function updateCategoryHours(updateAppointmentsMethod) {
        var _this6 = this;

        var category = this.get('selectedCategory');
        var activeTimeFrames = category.get('time_frames').filter(function (tf) {
          return !tf.get('markedForDeletion');
        });
        activeTimeFrames = activeTimeFrames.map(function (tf) {
          return {
            start_time: tf.get('start_time'),
            end_time: tf.get('end_time'),
            is_off_hours: tf.get('is_off_hours'),
            days_of_week: tf.get('days_of_week')
          };
        });
        this.set('showUpdatingTimeFramesModal', false);
        this.set('loading', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/service_categories/").concat(category.id, "/update_hours"),
          method: 'POST',
          data: {
            use_time_blocks: category.get('use_time_blocks'),
            time_frames: activeTimeFrames,
            time_window_in_minutes: category.get('time_window_in_minutes'),
            update_appointments_method: updateAppointmentsMethod
          }
        }).then(function () {
          category.get('time_frames').forEach(function (tf) {
            if (tf) {
              tf.rollbackAttributes();
            }
          });

          _this6.send('refreshModel');
        });
      },
      changeTab: function changeTab(tab) {
        this.transitionToRoute('companies.category-settings', this.get('company.id'), tab, {
          queryParams: {
            categoryId: this.get('categoryId')
          }
        });
      },
      toggleCancellationFeePolicy: function toggleCancellationFeePolicy() {
        var _this7 = this;

        if (this.get('selectedCategory.hasCancellationFeePolicies')) {
          this.set('deletingAllCancellationPolices', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/cancellation_fee_policies/delete_all"),
            method: 'POST'
          }).then(function () {
            _this7.get('selectedCategory.cancellation_fee_policies').forEach(function (policy) {
              policy.deleteRecord();
            });

            _this7.set('deletingAllCancellationPolices', false);
          });
        } else {
          this.send('createCancellationFeePolicy');
        }
      },
      toggleWeekendPolicy: function toggleWeekendPolicy() {
        if (!this.get('selectedCategory.weekend_surcharge_policy')) {
          this.store.createRecord('weekend-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            days_of_week: [0, 6]
          });
          this.set('showWeekendPoliciesModal', true);
        } else {
          this.get('selectedCategory.weekend_surcharge_policy').destroyRecord();
        }
      },
      toggleOffHoursPolicy: function toggleOffHoursPolicy() {
        if (!this.get('selectedCategory.off_hours_surcharge_policy')) {
          var time_frames = this.get('selectedCategory.time_frames').filter(function (tf) {
            return tf.get('is_off_hours');
          });
          this.store.createRecord('off-hours-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            time_frames: time_frames
          });
          this.set('showOffHoursPoliciesModal', true);
        } else {
          this.get('selectedCategory.off_hours_surcharge_policy').destroyRecord();
        }
      },
      toggleCutOffPolicy: function toggleCutOffPolicy() {
        if (!this.get('selectedCategory.request_cut_off_policy')) {
          this.store.createRecord('request_cut_off_policy', {
            service_category: this.get('selectedCategory'),
            method: 'after',
            days_before: 0,
            within_minutes: 60
          });
          this.set('showCutOffPoliciesModal', true);
        } else {
          this.get('selectedCategory.request_cut_off_policy').destroyRecord();
        }
      },
      toggleCheckInReminderToggle: function toggleCheckInReminderToggle() {
        if (!this.get('selectedCategory.checkInAlertTimeInMinutes')) {
          this.set('selectedCategory.checkInAlertTimeInMinutes', 15);
          this.send('showLateNotificationsModal', 'arrival');
          this.set('lateNotificationsNewRecord', true);
        } else {
          this.set('selectedCategory.checkInAlertTimeInMinutes', null);
          this.get('selectedCategory').updateCheckInAlertTiming();
        }
      },
      toggleCheckOutReminderToggle: function toggleCheckOutReminderToggle() {
        if (!this.get('selectedCategory.checkOutAlertTimeInMinutes')) {
          this.set('selectedCategory.checkOutAlertTimeInMinutes', 15);
          this.send('showLateNotificationsModal', 'check out');
          this.set('lateNotificationsNewRecord', true);
        } else {
          this.set('selectedCategory.checkOutAlertTimeInMinutes', null);
          this.get('selectedCategory').updateCheckOutAlertTiming();
        }
      },
      showCutOffPolicyModal: function showCutOffPolicyModal() {
        this.set('showCutOffPoliciesModal', true);
      },
      closeCutOffPolicyModal: function closeCutOffPolicyModal() {
        this.set('showCutOffPoliciesModal', false);
      },
      showWeekendPoliciesModal: function showWeekendPoliciesModal() {
        this.set('showWeekendPoliciesModal', true);
      },
      closeWeekendPolicyModal: function closeWeekendPolicyModal() {
        this.set('showWeekendPoliciesModal', false);
      },
      showOffHoursPoliciesModal: function showOffHoursPoliciesModal() {
        this.set('showOffHoursPoliciesModal', true);
      },
      closeOffHoursPolicyModal: function closeOffHoursPolicyModal() {
        this.set('showOffHoursPoliciesModal', false);
      },
      showLateNotificationsModal: function showLateNotificationsModal(notificationType) {
        this.set('showLateNotificationsModal', true);
        this.set('lateNotificationsSelectedType', notificationType);

        if (notificationType == 'arrival') {
          this.set('originalLateNotificationTime', this.get('selectedCategory.checkInAlertTimeInMinutes'));
        } else {
          this.set('originalLateNotificationTime', this.get('selectedCategory.checkOutAlertTimeInMinutes'));
        }
      },
      closeLateNotificationsModal: function closeLateNotificationsModal() {
        var rollback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.set('showLateNotificationsModal', false);
        this.set('lateNotificationsNewRecord', false);

        if (rollback) {
          if (this.get('lateNotificationsSelectedType') == 'arrival') {
            this.set('selectedCategory.checkInAlertTimeInMinutes', this.get('originalLateNotificationTime'));
          } else {
            this.set('selectedCategory.checkOutAlertTimeInMinutes', this.get('originalLateNotificationTime'));
          }
        }
      },
      toggleLateBookingPolicy: function toggleLateBookingPolicy() {
        if (!this.get('selectedCategory.late_booking_surcharge_policy')) {
          this.store.createRecord('late-booking-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            method: 'after',
            days_before: 0,
            within_minutes: 60
          });
          this.set('showLateBookingPolicyModal', true);
        } else {
          this.get('selectedCategory.late_booking_surcharge_policy').destroyRecord();
        }
      },
      showLateBookingPolicyModal: function showLateBookingPolicyModal() {
        this.set('showLateBookingPolicyModal', true);
      },
      closeLateBookingPolicyModal: function closeLateBookingPolicyModal() {
        this.set('showLateBookingPolicyModal', false);
      },
      toggleHolidayPolicy: function toggleHolidayPolicy() {
        if (!this.get('selectedCategory.holiday_surcharge_policy')) {
          this.store.createRecord('holiday-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            dates: []
          });
          this.set('showHolidayPolicyModal', 'true');
        } else {
          this.get('selectedCategory.holiday_surcharge_policy').destroyRecord();
        }
      },
      showHolidayPolicyModal: function showHolidayPolicyModal() {
        this.set('showHolidayPolicyModal', 'true');
      },
      closeHolidayPolicyModal: function closeHolidayPolicyModal() {
        this.set('showHolidayPolicyModal', 'false');
      },
      createCancellationFeePolicy: function createCancellationFeePolicy() {
        var newCancellationFee = this.store.createRecord('cancellation-fee-policy', {
          service_category: this.get('selectedCategory'),
          amount_type: 'percentage',
          method: 'after',
          days_before: 0,
          within_minutes: 60,
          employee_compensation_type: 'percentage'
        });
        this.set('selectedCancellationFeePolicy', newCancellationFee);
        this.set('showCancellationFeePolicyModal', true);
      },
      saveCancellationFeePolicy: function saveCancellationFeePolicy() {
        var _this8 = this;

        this.get('selectedCancellationFeePolicy').save().then(function () {
          _this8.store.query('service-category', {}, {
            reload: true
          }).then(function (serviceCategories) {
            _this8.set('model', serviceCategories);

            _this8.set('showCancellationFeePolicyModal', false);

            _this8.set('selectedCancellationFeePolicy', null);
          });
        });
      },
      toggleCancellationFeePolicyModal: function toggleCancellationFeePolicyModal() {
        this.get('selectedCancellationFeePolicy').rollbackAttributes();
        this.toggleProperty('showCancellationFeePolicyModal');
      },
      selectCancellationFeePolicy: function selectCancellationFeePolicy(policy) {
        this.set('selectedCancellationFeePolicy', policy);
        this.set('showCancellationFeePolicyModal', true);
      },
      removeCancellationFeePolicy: function removeCancellationFeePolicy(policy) {
        this.set('cancellationFeePolicyToRemove', policy);
        this.set('showRemoveCancellationFeePolicyModal', true);
        this.set('showCancellationFeePolicyModal', false);
      },
      denyRemoveCancellationPolicy: function denyRemoveCancellationPolicy() {
        this.set('cancellationFeePolicyToRemove', null);
        this.set('showRemoveCancellationFeePolicyModal', false);
      },
      confirmRemoveCancellationPolicy: function confirmRemoveCancellationPolicy() {
        var _this9 = this;

        this.get('cancellationFeePolicyToRemove').destroyRecord().then(function () {
          _this9.set('cancellationFeePolicyToRemove', null);

          _this9.set('showRemoveCancellationFeePolicyModal', false);
        });
      },
      toggleReportCardsRequired: function toggleReportCardsRequired() {
        this.set('selectedCategory.show_report_card_on_completion', !this.get('selectedCategory.show_report_card_on_completion'));
        this.get('selectedCategory').updateReportCard();
      },
      toggleCheckInCheckOutRequired: function toggleCheckInCheckOutRequired() {
        this.set('selectedCategory.show_check_in_check_out_appointment', !this.get('selectedCategory.show_check_in_check_out_appointment'));
        this.get('selectedCategory').updateCheckInCheckOut();
      },
      toggleGps: function toggleGps() {
        this.set('selectedCategory.enable_gps_on_check_in', !this.get('selectedCategory.enable_gps_on_check_in'));
        this.get('selectedCategory').updateGps();
      }
    }
  });

  _exports.default = _default;
});